import React, { useState } from 'react';
import './styles.scss';
import { useNavigate } from 'react-router-dom';
import Assets from '../../assets';
import Dropdown from 'react-bootstrap/Dropdown';
import SVG from '../../assets/svg';

const HostDiscoveryVprTopThreats = () => {
  const navigate = useNavigate();

  const navigateUiOverview = () => {
    navigate('/admin/new-scan/scan-templates/host-discovery/overview');
  };

  const navigateUiTohosts = () => {
    navigate('/admin/new-scan/scan-templates/host-discovery/hosts');
  };

  const navigateToVulnerabilities = () => {
    navigate('/admin/new-scan/scan-templates/host-discovery/vulnerabilities');
  };

  const navigateToHistory = () => {
    navigate('/admin/new-scan/scan-templates/host-discovery/history');
  };

  const navigateToDashboard = () => {
    navigate('/admin/dashboard');
  };

  const navigateToVprTopThreats = () => {
    navigate('/admin/new-scan/scan-templates/host-discovery/vpr-top-threats');
  };

  const navigateToRemediations = () => {
    navigate('/admin/new-scan/scan-templates/host-discovery/remediations');
  };

  const data = [
    {
      name: 'MS 17-010: Security Update for Microsoft Windows SMB Server 4013389 (Eternal Blue ',
      reasons: 'Social Media ; Dark Web.....',
      vprScore: 9.9,
      hosts: 1,
    },
    {
      name: 'MS 17-010: Security Update for Microsoft Windows SMB Server 4013389 (Eternal Blue ',
      reasons: 'Security Research',
      vprScore: 9.9,
      hosts: 1,
    },
    {
      name: 'MS 17-010: Security Update for Microsoft Windows SMB Server 4013389 (Eternal Blue ',
      reasons: 'Security Research',
      vprScore: 9.9,
      hosts: 1,
    },
    {
      name: 'MS 17-010: Security Update for Microsoft Windows SMB Server 4013389 (Eternal Blue ',
      reasons: 'Security Research',
      vprScore: 9.9,
      hosts: 1,
    },
    {
      name: 'MS 17-010: Security Update for Microsoft Windows SMB Server 4013389 (Eternal Blue ',
      reasons: 'Security Research',
      vprScore: 9.9,
      hosts: 1,
    },
    {
      name: 'MS 17-010: Security Update for Microsoft Windows SMB Server 4013389 (Eternal Blue ',
      reasons: 'Security Research',
      vprScore: 9.9,
      hosts: 1,
    },
  ];
  return (
    <div className='sub-header-vpr'>
      <div className='container'>
        <div className='sub-header-content'>
          <div className='sub-header-vpr-content'>
            <div>
              <img
                src={Assets.Icons.backArrow}
                alt='back arrow icon'
                className='back-arrow'
                onClick={navigateToDashboard}
              />
              <span className='vpr-text'>HOST DISCOVERY - LOCAL NETWORK</span>
              <div>
                <span className='scan-type-vpr'>
                  <span>New Scan</span>{' '}
                  <img src={Assets.Icons.greaterIcon} alt='Greater Icon' />{' '}
                  <span>Scan Templates</span>
                  <img src={Assets.Icons.greaterIcon} alt='Greater Icon' />{' '}
                  <span className='advanced-scan-text'>
                    Host Discovery - Local Network
                  </span>{' '}
                </span>
              </div>
            </div>
            <div className='completed-vpr'>
              <span className='completed-text-vpr'>In-Progress</span>
            </div>
            <div className='options-btn-vpr d-flex'>
              <Dropdown>
                <Dropdown.Toggle variant='none' id='dropdown-basic'>
                  Configure
                </Dropdown.Toggle>
              </Dropdown>
              <Dropdown>
                <Dropdown.Toggle variant='none' id='dropdown-basic'>
                  Export
                </Dropdown.Toggle>
              </Dropdown>
              <Dropdown>
                <Dropdown.Toggle
                  variant='none'
                  id='dropdown-basic'
                  className='text-white'
                >
                  Report
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item href='#'>
                    <img
                      src={Assets.Images.pdfIcon}
                      height={'22px'}
                      width={'22px'}
                    />
                    <span className='mx-2'>PDF</span>
                  </Dropdown.Item>
                  <Dropdown.Item href='#'>
                    <img
                      src={Assets.Images.ExcelIcon}
                      height={'22px'}
                      width={'22px'}
                    />
                    <span className='mx-2'>Excel</span>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </div>
        <div className='tabs-vpr'>
          <button onClick={navigateUiOverview}>Overview</button>
          <button onClick={navigateUiTohosts}>Hosts</button>
          <button onClick={navigateToVulnerabilities}>Vulnerabilities</button>
          <button onClick={navigateToRemediations}>Remediations</button>
          <button onClick={navigateToVprTopThreats}>VPR Top Threats</button>
          <button onClick={navigateToHistory}>History</button>
        </div>
        <div className='sub-content-vpr'>
          <div className='sub-content-title-vpr'>
            <span>Assessed Threat Level</span>
            <hr style={{ backgroundColor: '#E7E7E7' }} />
          </div>
          <div className='d-flex justify-content-around py-2 px-2 align-items-center'>
            <div>
              <img src={Assets.Images.securityLock} />
            </div>
            <div className='ms-2'>
              <span className='critical-text-vpr'>Critical</span>
              <p className='critical-content-vpr'>
                Delve into our arsenal of cutting-edge tools and technologies,
                featuring Nessus, Burpsuite and other industry-leading
                solutions, to fortify your defenses when facing a "Critical"
                threat assessment.{' '}
              </p>
            </div>
          </div>
        </div>
        <div className='sub-content-table-vpr'>
          <div className='table-title-vpr'>
            <span>VPR Top Threats</span>
            <span>
              Navigate the Digital Battlefield: VPR's Top Threats Spotlight
            </span>
            <hr />
          </div>
          <div className='search-content-row'>
            <div className='search-vpr'>
              <img src={Assets.Icons.search} alt='Search Icon' />
              <input type='text' placeholder='Search for hosts' />
            </div>
            <div className='vpr-dropdown'>
              <Dropdown>
                <Dropdown.Toggle variant='none' id='dropdown-basic'>
                  Approver Status
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item href='#'>
                    <input type='checkbox' height={'22px'} width={'22px'} />
                    <span className='mx-2'>Define Rule</span>
                  </Dropdown.Item>
                  <Dropdown.Item href='#'>
                    <input type='checkbox' height={'22px'} width={'22px'} />
                    <span className='mx-2'>Configure Rule</span>
                  </Dropdown.Item>
                  <Dropdown.Item href='#'>
                    <input type='checkbox' height={'22px'} width={'22px'} />
                    <span className='mx-2'>Set Values</span>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
          <div>
            <table className='custom-table-vpr'>
              <thead>
                <tr>
                  <th className='table-header'>
                    {' '}
                    <input type='checkbox' className='checkbox-hdvpr' />
                    Name
                  </th>
                  <th className='table-header'>Reasons </th>
                  <th className='table-header'>VPR Score </th>
                  <th className='table-header'>Hosts</th>
                  <th className='table-header'>VPR Severity</th>
                </tr>
              </thead>
              <tbody>
                {data.map((item, index) => (
                  <>
                    <tr
                      key={index}
                      className={index % 2 === 1 ? 'colored-row' : 'white-row'}
                    >
                      <td className='table-data' style={{ width: '65%' }}>
                        <input type='checkbox' className='checkbox-hdvpr' />

                        {item.name}
                      </td>
                      <td className='table-data' style={{ width: '20%' }}>
                        {item.reasons}
                      </td>
                      <td className='table-data' style={{ width: '9%' }}>
                        {item.vprScore}
                      </td>
                      <td className='table-data' style={{ width: '1%' }}>
                        {item.hosts}
                      </td>
                      <td className='table-data' style={{ width: '15%' }}>
                        <div className='badge-hdvpr'> Critical</div>
                      </td>
                    </tr>
                  </>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HostDiscoveryVprTopThreats;
