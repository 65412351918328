// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fields-container {
  background-color: #fff;
  border-radius: 10px;
  padding: 30px;
  width: 40%;
  margin: auto;
}

.title {
  text-align: center;
  margin-top: 50px;
}`, "",{"version":3,"sources":["webpack://./src/screens/changePassword/styles.scss"],"names":[],"mappings":"AAAA;EACI,sBAAA;EACA,mBAAA;EACA,aAAA;EACA,UAAA;EACA,YAAA;AACJ;;AAEA;EACI,kBAAA;EACA,gBAAA;AACJ","sourcesContent":[".fields-container{\r\n    background-color: #fff;\r\n    border-radius: 10px;\r\n    padding: 30px;\r\n    width: 40%;\r\n    margin: auto;\r\n}\r\n\r\n.title{\r\n    text-align: center;\r\n    margin-top: 50px ;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
