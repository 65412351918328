// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.success {
  background-color: #36895e;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 10px;
  text-transform: capitalize;
}

.error {
  background-color: #F87171;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 10px;
  text-transform: capitalize;
}

.view-details {
  color: #E44444;
  cursor: pointer;
}

.search-input {
  width: 300px;
  position: absolute;
  right: 0px;
}

.iconButton {
  background: white;
  border: 1px solid rgb(205, 205, 205);
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  width: 42px;
  height: 42px;
  cursor: pointer;
  margin-top: 10px;
}

.search-container {
  margin-right: 10px;
}

.showLabel {
  color: rgb(174, 174, 174);
  margin-top: 20px;
}

.holiday-selectBox {
  outline: none;
  border: 1px solid rgb(197, 197, 197);
  border-radius: 0px 6px 6px 0px;
  background: #f9fafb;
  height: 42px;
  width: 80px;
  cursor: pointer;
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/screens/userTypes/styles.scss"],"names":[],"mappings":"AAAA;EACI,yBAAA;EACA,kBAAA;EACA,mBAAA;EACA,mBAAA;EACA,0BAAA;AACJ;;AAEA;EACI,yBAAA;EACA,kBAAA;EACA,mBAAA;EACA,mBAAA;EACA,0BAAA;AACJ;;AAEA;EACI,cAAA;EACA,eAAA;AACJ;;AAEA;EACI,YAAA;EACA,kBAAA;EACA,UAAA;AACJ;;AAEA;EACI,iBAAA;EACA,oCAAA;EACA,2CAAA;EACA,kBAAA;EACA,WAAA;EACA,YAAA;EACA,eAAA;EACA,gBAAA;AACJ;;AAEA;EACI,kBAAA;AACJ;;AACA;EACI,yBAAA;EAEA,gBAAA;AACJ;;AAEA;EACI,aAAA;EACA,oCAAA;EACA,8BAAA;EACA,mBAAA;EACA,YAAA;EACA,WAAA;EACA,eAAA;EACA,kBAAA;AACJ","sourcesContent":[".success{\r\n    background-color: #36895e;\r\n    padding-left: 10px;\r\n    padding-right: 10px;\r\n    border-radius: 10px;\r\n    text-transform: capitalize;\r\n}\r\n\r\n.error{\r\n    background-color: #F87171;\r\n    padding-left: 10px;\r\n    padding-right: 10px;\r\n    border-radius: 10px;\r\n    text-transform: capitalize;\r\n}\r\n\r\n.view-details{\r\n    color: #E44444;\r\n    cursor: pointer;\r\n}\r\n\r\n.search-input{\r\n    width: 300px;\r\n    position: absolute;\r\n    right: 0px;\r\n}\r\n\r\n.iconButton {\r\n    background: white;\r\n    border: 1px solid rgb(205, 205, 205);\r\n    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);\r\n    border-radius: 6px;\r\n    width: 42px;\r\n    height: 42px;\r\n    cursor: pointer;\r\n    margin-top: 10px;\r\n}\r\n\r\n.search-container{\r\n    margin-right: 10px;\r\n}\r\n.showLabel {\r\n    color: rgb(174, 174, 174);\r\n\r\n    margin-top: 20px;\r\n}\r\n\r\n.holiday-selectBox {\r\n    outline: none;\r\n    border: 1px solid rgb(197, 197, 197);\r\n    border-radius: 0px 6px 6px 0px;\r\n    background: #f9fafb;\r\n    height: 42px;\r\n    width: 80px;\r\n    cursor: pointer;\r\n    text-align: center;\r\n  }\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
