import React, { useState } from 'react';
import './styles.scss';
import Assets from '../../assets';
import { useNavigate, useLocation } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import SVG from '../../assets/svg';
import Utils from '../../utils';

const HostDiscoveryVulnerabilities = () => {
  const [show, setShow] = useState(false);
  const [showDropdownContent, setShowDropdownContent] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const selectedSeverity = new URLSearchParams(location.search)?.get('label');
  const navigateUiOverview = () => {
    navigate('/admin/new-scan/scan-templates/host-discovery/overview');
  };

  const navigateUiTohosts = () => {
    navigate('/admin/new-scan/scan-templates/host-discovery/hosts');
  };

  const navigateToVulnerabilities = () => {
    navigate('/admin/new-scan/scan-templates/host-discovery/vulnerabilities');
  };

  const navigateToHistory = () => {
    navigate('/admin/new-scan/scan-templates/host-discovery/history');
  };

  const navigateToDashboard = () => {
    navigate('/admin/dashboard');
  };
  const navigateToVprTopThreats = () => {
    navigate('/admin/new-scan/scan-templates/host-discovery/vpr-top-threats');
  };

  const navigateToRemediations = () => {
    navigate('/admin/new-scan/scan-templates/host-discovery/remediations');
  };

  const data = [
    {
      name: 'Apache Httpd (multiple Issues)',
      family: 'Web Servers',
      count: 5,
      action: ['pause', 'stop'],
      severity: 'Critical',
    },
    {
      name: 'Apache Httpd (multiple Issues)',
      family: 'Web Servers',
      count: 2,
      action: ['pause', 'stop'],
      severity: 'Mixed',
    },
    {
      name: 'Apache Httpd (multiple Issues)',
      family: 'Web Servers',
      count: 5,
      action: ['pause', 'stop'],
      severity: 'Medium',
    },
    {
      name: 'Apache Httpd (multiple Issues)',
      family: 'Web Servers',
      count: 3,
      action: ['pause', 'stop'],
      severity: 'Info',
    },
    {
      name: 'Apache Httpd (multiple Issues)',
      family: 'Web Servers',
      count: 2,
      action: ['pause', 'stop'],
      severity: 'High',
    },
    {
      name: 'Apache Httpd (multiple Issues)',
      family: 'Web Servers',
      count: 8,
      action: ['pause', 'stop'],
      severity: 'Low',
    },
    {
      name: 'Apache Httpd (multiple Issues)',
      family: 'Web Servers',
      count: 2,
      action: ['pause', 'stop'],
      severity: 'critical',
    },
    {
      name: 'Apache Httpd (multiple Issues)',
      family: 'Web Servers',
      count: 5,
      action: ['pause', 'stop'],
      severity: 'Info',
    },
    {
      name: 'Apache Httpd (multiple Issues)',
      family: 'Web Servers',
      count: 4,
      action: ['pause', 'stop'],
      severity: 'critical',
    },
    {
      name: 'Apache Httpd (multiple Issues)',
      family: 'Web Servers',
      count: 5,
      action: ['pause', 'stop'],
      severity: 'critical',
    },
  ];

  const filteredData = selectedSeverity
    ? data.filter(
        (item) => item.severity.toLowerCase() === selectedSeverity.toLowerCase()
      )
    : data;

  console.log(filteredData);

  return (
    <div>
      <div className='sub-header-hdvulnerabilities'>
        <div className='container'>
          <div className='sub-header-content'>
            <div className='sub-header-hdvulnerabilities-content'>
              <div>
                <SVG.ArrowBack
                  className='back-arrow'
                  onClick={navigateToDashboard}
                  alt='back arrow icon'
                />
                <span className='hdvulnerabilities-text'>
                  HOST DISCOVERY - LOCAL NETWORK
                </span>
                <div>
                  <span className='scan-type-hdvulnerabilities'>
                    <span>New Scan</span>{' '}
                    <img src={Assets.Icons.greaterIcon} alt='Greater Icon' />{' '}
                    <span>Scan Templates</span>
                    <img
                      src={Assets.Icons.greaterIcon}
                      alt='Greater Icon'
                    />{' '}
                    <span className='advanced-scan-text'>
                      Host Discovery - Local Network
                    </span>{' '}
                  </span>
                </div>
              </div>
              <div className='completed-hdvulnerabilities'>
                <span className='completed-text-hdvulnerabilities'>
                  In-Progress
                </span>
              </div>
              <div className='options-btn-hdvulnerabilities d-flex'>
                <Dropdown>
                  <Dropdown.Toggle variant='none' id='dropdown-basic'>
                    Configure
                  </Dropdown.Toggle>
                </Dropdown>
                <Dropdown>
                  <Dropdown.Toggle variant='none' id='dropdown-basic'>
                    Export
                  </Dropdown.Toggle>
                </Dropdown>
                <Dropdown>
                  <Dropdown.Toggle
                    variant='none'
                    id='dropdown-basic'
                    className='text-white'
                  >
                    Report
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item href='#'>
                      <img
                        src={Assets.Images.pdfIcon}
                        height={'22px'}
                        width={'22px'}
                      />
                      <span className='mx-2'>PDF</span>
                    </Dropdown.Item>
                    <Dropdown.Item href='#'>
                      <img
                        src={Assets.Images.ExcelIcon}
                        height={'22px'}
                        width={'22px'}
                      />
                      <span className='mx-2'>Excel</span>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
          <div className='tabs-hdvulnerabilities'>
            <button onClick={navigateUiOverview}>Overview</button>
            <button onClick={navigateUiTohosts}>Hosts</button>
            <button onClick={navigateToVulnerabilities}>Vulnerabilities</button>
            <button onClick={navigateToRemediations}>Remediations</button>
            <button onClick={navigateToVprTopThreats}>VPR Top Threats</button>
            <button onClick={navigateToHistory}>History</button>
          </div>
        </div>
      </div>
      {!showDropdownContent ? (
        <div className='hdvulnerabilities-content'>
          <div className='hdvulnerabilities-subcontent'>
            <span className='hosts-title'>Vulnerabilities</span>
            <p className='hosts-subtext'>
              Uncover Security Weaknesses: Dive into Vulnerabilities
            </p>
            <div className='underline-hdvulnerabilities'></div>
            <div className='search-filter-hdvulnerabilities'>
              <div className='search-hdvulnerabilities'>
                <img src={Assets.Icons.search} alt='Search Icon' />
                <input type='text' placeholder='Search for hosts' />
              </div>
              <div className='approverStatus-dropdown'>
                <Dropdown>
                  <Dropdown.Toggle variant='none' id='dropdown-basic'>
                    Approver Status
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item href='#'>
                      <input type='checkbox' height={'22px'} width={'22px'} />
                      <span className='mx-2'>Define Rule</span>
                    </Dropdown.Item>
                    <Dropdown.Item href='#'>
                      <input type='checkbox' height={'22px'} width={'22px'} />
                      <span className='mx-2'>Configure Rule</span>
                    </Dropdown.Item>
                    <Dropdown.Item href='#'>
                      <input type='checkbox' height={'22px'} width={'22px'} />
                      <span className='mx-2'>Set Values</span>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <div className='sev-dropdown'>
                <Dropdown>
                  <Dropdown.Toggle variant='none' id='dropdown-basic'>
                    Sev
                  </Dropdown.Toggle>

                  {/* <Dropdown.Menu>
                  <Dropdown.Item href='#'>
                    <input type='checkbox' />
                    <span className='mx-2'>Define Rule</span>
                  </Dropdown.Item>
                  <Dropdown.Item href='#'>
                    <input type='checkbox' />
                    <span className='mx-2'>Configure Rule</span>
                  </Dropdown.Item>
                  <Dropdown.Item href='#'>
                    <input type='checkbox' />
                    <span className='mx-2'>Set Values</span>
                  </Dropdown.Item>
                </Dropdown.Menu> */}
                </Dropdown>
              </div>
            </div>
          </div>
          <div>
            <table className='custom-table-vulnerabilities'>
              <thead>
                <tr>
                  <th className='table-header'>
                    {' '}
                    <input
                      type='checkbox'
                      className='checkbox-hdvulnerabilities'
                    />
                    Name
                    <img src={Assets.Icons.upAndDownArrow} className='ms-2' />
                  </th>
                  <th className='table-header'>
                    Family{' '}
                    <img src={Assets.Icons.upAndDownArrow} className='ms-2' />
                  </th>
                  <th className='table-header'>
                    Count{' '}
                    <img src={Assets.Icons.upAndDownArrow} className='ms-2' />
                  </th>
                  <th className='table-header'>sev</th>
                  <th className='table-header'>Action</th>
                </tr>
              </thead>
              <tbody>
                {filteredData.map((item, index) => (
                  <>
                    <tr
                      key={index}
                      className={index % 2 === 1 ? 'colored-row' : 'white-row'}
                    >
                      <td className='table-data' style={{ width: '30%' }}>
                        <input
                          type='checkbox'
                          className='checkbox-hdvulnerabilities'
                        />
                        <span className='me-2'>
                          <img
                            src={
                              show && index === 0
                                ? Assets.Icons.upArrow
                                : Assets.Icons.rightArrow
                            }
                            className={show ? 'upArrowVul' : 'rightArrowVul'}
                            onClick={() => {
                              if (index === 0) {
                                setShow((prevShow) => !prevShow);
                              }
                            }}
                          />
                        </span>
                        {item.name}
                      </td>
                      <td className='table-data' style={{ width: '20%' }}>
                        {item.family}
                      </td>
                      <td className='table-data' style={{ width: '15%' }}>
                        {item.count}
                      </td>
                      <td className='table-data' style={{ width: '20%' }}>
                        <div
                          className='badge-hdhistory'
                          style={{
                            ...Utils.dynamicBadgeStyle.getStatusBadgeStyle(
                              item.severity
                            ),
                          }}
                        >
                          {item.severity}
                        </div>
                      </td>
                      <td className='table-data'>
                        {item.action.length > 0 && (
                          <div className='action-icon-box'>
                            {!show &&
                              item.action.map((action, index) => (
                                <React.Fragment key={index}>
                                  {action === 'pause' && (
                                    <SVG.Pause className='action-icon' />
                                  )}
                                  {action === 'stop' && (
                                    <SVG.Stop className='action-icon' />
                                  )}
                                  {action === 'edit' && (
                                    <SVG.Edit className='action-icon' />
                                  )}
                                  {action === 'refresh' && (
                                    <SVG.Refresh className='action-icon' />
                                  )}
                                  {action === 'delete' && (
                                    <SVG.Delete className='action-icon' />
                                  )}
                                </React.Fragment>
                              ))}
                          </div>
                        )}
                      </td>
                    </tr>
                    {show && index == 0 && (
                      <>
                        <tr>
                          <td
                            className='table-data bg-light'
                            style={{ width: '25%' }}
                            onClick={() =>
                              setShowDropdownContent(
                                (prevShowDropdownContent) =>
                                  !prevShowDropdownContent
                              )
                            }
                          >
                            <span className='ms-75'>
                              Apache Httpd (multiple Issues)
                            </span>
                          </td>
                          <td
                            className='table-data bg-light'
                            style={{ width: '25%' }}
                          >
                            <span className='ms-2'>Web Servers</span>
                          </td>
                          <td
                            className='table-data bg-light'
                            style={{ width: '20%' }}
                            onClick={() =>
                              setShowDropdownContent(
                                (prevShowDropdownContent) =>
                                  !prevShowDropdownContent
                              )
                            }
                          >
                            4
                          </td>
                          <td
                            className='table-data bg-light'
                            style={{ width: '20%' }}
                          >
                            <div className='badge-hdvulnerabilities'>
                              {' '}
                              Critical
                            </div>
                          </td>
                          <td className='table-data bg-light'>
                            {item.action.length > 0 && (
                              <div className='action-icon-box'>
                                {show &&
                                  item.action.map((action, index) => (
                                    <React.Fragment key={index}>
                                      {action === 'pause' && (
                                        <SVG.Pause className='action-icon' />
                                      )}
                                      {action === 'stop' && (
                                        <SVG.Stop className='action-icon' />
                                      )}
                                      {action === 'edit' && (
                                        <SVG.Edit className='action-icon' />
                                      )}
                                      {action === 'refresh' && (
                                        <SVG.Refresh className='action-icon' />
                                      )}
                                      {action === 'delete' && (
                                        <SVG.Delete className='action-icon' />
                                      )}
                                    </React.Fragment>
                                  ))}
                              </div>
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td
                            className='table-data bg-light'
                            style={{ width: '25%' }}
                          >
                            <span className='ms-75'>
                              Apache Httpd (multiple Issues)
                            </span>
                          </td>
                          <td
                            className='table-data bg-light'
                            style={{ width: '25%' }}
                          >
                            <span className='ms-2'>Web Servers</span>
                          </td>
                          <td
                            className='table-data bg-light'
                            style={{ width: '20%' }}
                          >
                            4
                          </td>
                          <td
                            className='table-data bg-light'
                            style={{ width: '20%' }}
                          >
                            <div className='badge-hdvulnerabilities'>
                              {' '}
                              Critical
                            </div>
                          </td>
                          <td className='table-data bg-light'>
                            {item.action.length > 0 && (
                              <div className='action-icon-box'>
                                {show &&
                                  item.action.map((action, index) => (
                                    <React.Fragment key={index}>
                                      {action === 'pause' && (
                                        <SVG.Pause className='action-icon' />
                                      )}
                                      {action === 'stop' && (
                                        <SVG.Stop className='action-icon' />
                                      )}
                                      {action === 'edit' && (
                                        <SVG.Edit className='action-icon' />
                                      )}
                                      {action === 'refresh' && (
                                        <SVG.Refresh className='action-icon' />
                                      )}
                                      {action === 'delete' && (
                                        <SVG.Delete className='action-icon' />
                                      )}
                                    </React.Fragment>
                                  ))}
                              </div>
                            )}
                          </td>
                        </tr>
                      </>
                    )}
                  </>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <div className='container dropdown-content-screen '>
          <div className='row'>
            <div className='col-7 horizontal-content'>
              <div className='header'>
                <div className='header-content'>
                  <img
                    src={Assets.Icons.backArrow}
                    height={24}
                    width={24}
                    onClick={() =>
                      setShowDropdownContent(
                        (prevShowDropdownContent) => !prevShowDropdownContent
                      )
                    }
                  />
                  <span className='mx-2'>
                    MS17-010: Security Update for Microsoft Windows SMB
                    Server(4013389) (ETERNALBLUE) ..
                  </span>
                  <div className='badge-hdvulnerabilities'> Critical</div>
                </div>
                <div className='description-content'>
                  <span className='description-text'>Description</span>
                  <p>
                    This is a security update issued by Microsoft known as
                    "MS17-010." This update is specifically designed to address
                    vulnerabilities in the Microsoft Windows Server Message
                    Block (SMB) Server, and it is identified by the KB number
                    "4013389."
                  </p>
                  <p>
                    The "ETERNALBLUE" reference is important as it relates to a
                    specific exploit that targeted a vulnerability in SMBv1, a
                    network file sharing protocol used by Windows operating
                    systems. This exploit gained significant attention due to
                    its association with the WannaCry ransomware attack in May
                    2017, which caused widespread disruption and damage to
                    computer systems worldwide.
                  </p>
                  <p>
                    In summary, MS17-010 is a security update from Microsoft
                    that addresses a critical vulnerability in the SMB Server,
                    and "ETERNALBLUE" is a reference to the exploit associated
                    with this vulnerability. It's essential to apply such
                    security updates to protect your system from potential
                    threats and exploits.
                  </p>
                </div>
                <div className='description-content'>
                  <span className='description-text'>Solutions</span>
                  <p>
                    <b>Install Security Updates:</b> Start by installing
                    Microsoft's security update (KB4013389) via Windows Update.
                    Ensure your system is up-to-date and reboot if necessary.
                  </p>
                  <p>
                    <b>Disable SMBv1:</b> If feasible, disable the outdated
                    SMBv1 protocol in your Windows settings to minimize
                    vulnerability.
                  </p>
                  <p>
                    <b>Enhance Overall Security:</b> Maintain a strong security
                    posture by regularly updating your system, implementing
                    network segmentation, employing robust security software,
                    enforcing stringent policies, and educating users. Monitor
                    network activity, configure firewall rules, and consider
                    upgrading to more secure Windows versions.
                  </p>
                </div>
                <div className='description-content'>
                  <span className='description-text'>Output</span>
                  <div className='output-boxes'>
                    <div>
                      <span>URL</span>
                      <span>:</span>
                      <span>http://192.168.2.245:8020/ </span>
                    </div>
                    <div>
                      <span>Installed Version</span>
                      <span>:</span>
                      <span>9 Build 91084</span>
                    </div>
                    <div>
                      <span>Fixed Version</span>
                      <span>:</span>
                      <span>9 Build 91100</span>
                    </div>
                    <div className='mt-3 ms-3'>
                      <span className='ports-text'>PORTS</span>
                    </div>
                    <hr className='line-ports' />
                    <div className='ms-3 tcp-content'>
                      <span>8020 / tcp / www</span>
                      <span className='ms-5'>192.168.32.56</span>
                    </div>
                  </div>
                  <div className='output-boxes'>
                    <div>
                      <span>URL</span>
                      <span>:</span>
                      <span>http://192.168.2.245:8020/ </span>
                    </div>
                    <div>
                      <span>Installed Version</span>
                      <span>:</span>
                      <span>9 Build 91084</span>
                    </div>
                    <div>
                      <span>Fixed Version</span>
                      <span>:</span>
                      <span>9 Build 91100</span>
                    </div>
                    <div className='mt-3 ms-3'>
                      <span className='ports-text'>PORTS</span>
                    </div>
                    <hr className='line-ports' />
                    <div className='ms-3 tcp-content'>
                      <span>8020 / tcp / www</span>
                      <span className='ms-5'>192.168.32.56</span>
                    </div>
                  </div>
                  <div className='output-boxes'>
                    <div>
                      <span>URL</span>
                      <span>:</span>
                      <span>http://192.168.2.245:8020/ </span>
                    </div>
                    <div>
                      <span>Installed Version</span>
                      <span>:</span>
                      <span>9 Build 91084</span>
                    </div>
                    <div>
                      <span>Fixed Version</span>
                      <span>:</span>
                      <span>9 Build 91100</span>
                    </div>
                    <div className='mt-3 ms-3'>
                      <span className='ports-text'>PORTS</span>
                    </div>
                    <hr className='line-ports' />
                    <div className='ms-3 tcp-content'>
                      <span>8020 / tcp / www</span>
                      <span className='ms-5'>192.168.32.56</span>
                    </div>
                  </div>
                  <div className='output-boxes'>
                    <div>
                      <span>URL</span>
                      <span>:</span>
                      <span>http://192.168.2.245:8020/ </span>
                    </div>
                    <div>
                      <span>Installed Version</span>
                      <span>:</span>
                      <span>9 Build 91084</span>
                    </div>
                    <div>
                      <span>Fixed Version</span>
                      <span>:</span>
                      <span>9 Build 91100</span>
                    </div>
                    <div className='mt-3 ms-3'>
                      <span className='ports-text'>PORTS</span>
                    </div>
                    <hr className='line-ports' />
                    <div className='ms-3 tcp-content'>
                      <span>8020 / tcp / www</span>
                      <span className='ms-5'>192.168.32.56</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='vertical-content col-3'>
              <div className='col-12 vertical-first-box'>
                <span className='plugin-details-text'>Plugin Details</span>
                <hr />
                <div className='d-flex justify-content-between px-4'>
                  <div>
                    <div className='plugin-details-content'>
                      <span>Severity:</span>
                      <span>High</span>
                    </div>

                    <div className='plugin-details-content'>
                      <span>Version:</span>
                      <span>1.24</span>
                    </div>
                    <div className='plugin-details-content'>
                      <span>Family:</span>
                      <span>Windows</span>
                    </div>
                    <div className='plugin-details-content'>
                      <span>Modified</span>
                      <span>Oct 15, 2020</span>
                    </div>
                  </div>
                  <div>
                    <div className='plugin-details-content'>
                      <span>ID</span>
                      <span>16956</span>
                    </div>
                    <div className='plugin-details-content'>
                      <span>Type</span>
                      <span>Remote</span>
                    </div>
                    <div className='plugin-details-content'>
                      <span>Published</span>
                      <span>March 20, 2017</span>
                    </div>
                  </div>
                </div>
              </div>

              <div className='col-12 vertical-second-box'>
                <span className='risk-information-text'>Risk Information</span>
                <hr />
                <div className='d-flex justify-content-between'>
                  <div>
                    <div className='risk-information-content'>
                      <span>CVSS v3.0 Base Score 8.1</span>
                      <span>CVSS v3.0 Vector: CVSS:3.0/AV:N/AC:H/</span>
                      <span>Risk Factor: High</span>
                    </div>

                    <div className='risk-information-content'>
                      <span>CVSS v3.0 Base Score 8.1</span>
                      <span>CVSS v3.0 Vector: CVSS:3.0/AV:N/AC:H/</span>
                      <span>Risk Factor: High</span>
                    </div>

                    <div className='risk-information-content'>
                      <span>CVSS v3.0 Base Score 8.1</span>
                      <span>CVSS v3.0 Vector: CVSS:3.0/AV:N/AC:H/</span>
                      <span>Risk Factor: High</span>
                    </div>

                    <div className='risk-information-content'>
                      <span>CVSS v3.0 Base Score 8.1</span>
                      <span>CVSS v3.0 Vector: CVSS:3.0/AV:N/AC:H/</span>
                      <span>Risk Factor: High</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default HostDiscoveryVulnerabilities;
