import React from 'react';
import './styles.scss';
import { useNavigate } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import Assets from '../../assets';
import Utils from '../../utils';
import SVG from '../../assets/svg';

const HostDiscoveryHistory = () => {
  const navigate = useNavigate();

  const navigateUiOverview = () => {
    navigate('/admin/new-scan/scan-templates/host-discovery/overview');
  };

  const navigateUiTohosts = () => {
    navigate('/admin/new-scan/scan-templates/host-discovery/hosts');
  };

  const navigateToVulnerabilities = () => {
    navigate('/admin/new-scan/scan-templates/host-discovery/vulnerabilities');
  };

  const navigateToHistory = () => {
    navigate('/admin/new-scan/scan-templates/host-discovery/history');
  };

  const navigateToRemediation = () => {
    navigate('/admin/new-scan/scan-templates/host-discovery/remediation');
  };
  const navigateToVprTopThreats = () => {
    navigate('/admin/new-scan/scan-templates/host-discovery/vpr-top-threats');
  };

  const navigateToDashboard = () => {
    navigate('/admin/dashboard');
  };

  const data = [
    {
      vulnerability: 35,
      action:
        'PHP 5.3x < 5.3.29 Multiple Vulnerabilities: Upgrade to PHP version 5.3.29 or later',
    },
    {
      vulnerability: 27,
      action:
        'Apache < 2.4.29 Multiple Vulnerabilities: Upgrade to Apache version 2,4,29 or later',
    },
    {
      vulnerability: 27,
      action:
        'Apache < 2.4.29 Multiple Vulnerabilities: Upgrade to Apache version 2,4,29 or later',
    },
    {
      vulnerability: 35,
      action:
        'PHP 5.3x < 5.3.29 Multiple Vulnerabilities: Upgrade to PHP version 5.3.29 or later',
    },
    {
      vulnerability: 23,
      action:
        'Apache < 2.4.29 Multiple Vulnerabilities: Upgrade to Apache version 2,4,29 or later',
    },
  ];

  return (
    <div>
      <div className='sub-header-remediation'>
        <div className='container'>
          <div className='sub-header-content'>
            <div className='sub-header-remediation-content'>
              <div>
                <SVG.ArrowBack
                  className='back-arrow'
                  onClick={navigateToDashboard}
                  alt='back arrow icon'
                />
                <span className='remediation-text'>
                  HOST DISCOVERY - LOCAL NETWORK
                </span>
                <div>
                  <span className='scan-type-remediation'>
                    <span>New Scan</span>{' '}
                    <img src={Assets.Icons.greaterIcon} alt='Greater Icon' />{' '}
                    <span>Scan Templates</span>
                    <img
                      src={Assets.Icons.greaterIcon}
                      alt='Greater Icon'
                    />{' '}
                    <span className='advanced-scan-text'>
                      Host Discovery - Local Network
                    </span>{' '}
                  </span>
                </div>
              </div>
              <div className='completed-remediation'>
                <span className='completed-text-remediation'>In-Progress</span>
              </div>
              <div className='options-btn-remediations d-flex'>
                <Dropdown>
                  <Dropdown.Toggle variant='none' id='dropdown-basic'>
                    Configure
                  </Dropdown.Toggle>
                </Dropdown>
                <Dropdown>
                  <Dropdown.Toggle variant='none' id='dropdown-basic'>
                    Export
                  </Dropdown.Toggle>
                </Dropdown>
                <Dropdown>
                  <Dropdown.Toggle
                    variant='none'
                    id='dropdown-basic'
                    className='text-white'
                  >
                    Report
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item href='#'>
                      <img
                        src={Assets.Images.pdfIcon}
                        height={'22px'}
                        width={'22px'}
                      />
                      <span className='mx-2'>PDF</span>
                    </Dropdown.Item>
                    <Dropdown.Item href='#'>
                      <img
                        src={Assets.Images.ExcelIcon}
                        height={'22px'}
                        width={'22px'}
                      />
                      <span className='mx-2'>Excel</span>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
          <div className='tabs-remediation'>
            <button onClick={navigateUiOverview}>Overview</button>
            <button onClick={navigateUiTohosts}>Hosts</button>
            <button onClick={navigateToVulnerabilities}>Vulnerabilities</button>
            <button onClick={navigateToRemediation}>Remediation</button>
            <button onClick={navigateToVprTopThreats}>VPR Top Threats</button>
            <button onClick={navigateToHistory}>History</button>
          </div>
        </div>
      </div>
      <div className='remediation-content'>
        <div className='remediation-subcontent'>
          <span className='hosts-title'>Remediation</span>
          <p className='hosts-subtext'>
            Unlocking Solutions, One Issue at a Time
          </p>
          <div className='underline-remediation'></div>
          <div className='search-filter-remediation'>
            <div className='search-remediation'>
              <img src={Assets.Icons.search} alt='Search Icon' />
              <input type='text' placeholder='Search by' />
            </div>
          </div>
        </div>
        <div>
          <table className='custom-table-remediation'>
            <thead>
              <tr>
                <th className='table-header'>
                  {' '}
                  <input type='checkbox' className='checkbox-remediation' />
                  Action
                </th>
                <th className='table-header'>
                  Vulns
                  <img
                    src={Assets.Icons.upAndDownArrow}
                    className='ms-2'
                    alt='Back Arrow'
                  />
                </th>
              </tr>
            </thead>
            <tbody>
              {data.map((item, index) => (
                <tr
                  key={index}
                  className={index % 2 === 1 ? 'colored-row' : 'white-row'}
                >
                  <td className='table-data' style={{ width: '70%' }}>
                    <input type='checkbox' className='checkbox-remediation' />
                    {item.action}
                  </td>
                  <td className='table-data' style={{ width: '30%' }}>
                    {item.vulnerability}{' '}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default HostDiscoveryHistory;
