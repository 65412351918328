import React, { useState } from 'react';
import './styles.scss';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { useNavigate } from 'react-router-dom';
import Assets from '../../assets';
import Dropdown from 'react-bootstrap/Dropdown';
import SVG from '../../assets/svg';

const HostDiscoveryOverview = () => {
  ChartJS.register(ArcElement, Tooltip, Legend);

  const navigate = useNavigate();

  const navigateUiOverview = () => {
    navigate('/admin/new-scan/scan-templates/host-discovery/overview');
  };

  const navigateUiTohosts = () => {
    navigate('/admin/new-scan/scan-templates/host-discovery/hosts');
  };

  const navigateToVulnerabilities = (label) => {
    const sanitizedLabel = typeof label === 'string' ? label : '';
    const url = `/admin/new-scan/scan-templates/host-discovery/vulnerabilities?label=${sanitizedLabel}`;
    navigate(url);
  };

  const navigateToHistory = () => {
    navigate('/admin/new-scan/scan-templates/host-discovery/history');
  };
  const navigateToRemediation = () => {
    navigate('/admin/new-scan/scan-templates/host-discovery/remediation');
  };
  const navigateToDashboard = () => {
    navigate('/admin/dashboard');
  };

  const navigateToVprTopThreats = () => {
    navigate('/admin/new-scan/scan-templates/host-discovery/vpr-top-threats');
  };

  const navigateToRemediations = () => {
    navigate('/admin/new-scan/scan-templates/host-discovery/remediations');
  };

  const pieChartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: 'right',
        fontSize: '16px',
        fontWeight: 400,
        letterSpacing: '0.0015em',
        fontFamily: 'Roboto_Regular',
        labels: {
          usePointStyle: true,
          pointStyle: 'rect',
          padding: 20,
          color: '#2E3751',
        },
        doughnutCenterText: {
          labels: [
            {
              text: 'Total',
              font: {
                size: '20',
                weight: 'bold',
              },
              color: 'white',
            },
            {
              text: '250',
              font: {
                size: '30',
                weight: 'bold',
              },
              color: 'white',
            },
          ],
        },
      },
      datalabels: {
        display: true,
        color: 'white',
      },
    },
  };

  const [pieChartData] = useState({
    labels: [
      'Critical - 24',
      'High - 22',
      'Medium - 12',
      'Low - 12',
      'Info - 12',
    ],
    datasets: [
      {
        label: '# of Votes',
        data: [24, 22, 12, 12, 12],
        backgroundColor: [
          '#D65D4D',
          '#D9842C',
          '#EBBF6A',
          '#54AC5E',
          '#6B81B9',
        ],
        borderColor: [
          'rgba(255, 255, 255, 1)',
          'rgba(255, 255, 255, 1)',
          'rgba(255, 255, 255, 1)',
          'rgba(255, 255, 255, 1)',
        ],
        borderWidth: 6,
      },
    ],
  });
  const pieChartTotalValue = pieChartData.datasets[0].data.reduce(
    (acc, value) => acc + value,
    0
  );

  const handleChartClick = (event, chartElements) => {
    if (chartElements.length > 0) {
      const chartElement = chartElements[0];
      const label = pieChartData.labels[chartElement.index];
      const selectedLabel = label?.split(' -')[0];
      navigateToVulnerabilities(selectedLabel);
    }
  };

  return (
    <div className='sub-header-hdLocal'>
      <div className='container'>
        <div className='sub-header-content'>
          <div className='sub-header-hdlocal-content'>
            <div>
              <SVG.ArrowBack
                className='back-arrow'
                onClick={navigateToDashboard}
                alt='back arrow icon'
              />
              <span className='hdlocal-text'>
                HOST DISCOVERY - LOCAL NETWORK
              </span>
              <div>
                <span className='scan-type-hdlocal'>
                  <span>New Scan</span>{' '}
                  <img src={Assets.Icons.greaterIcon} alt='Greater Icon' />{' '}
                  <span>Scan Templates</span>
                  <img src={Assets.Icons.greaterIcon} alt='Greater Icon' />{' '}
                  <span className='advanced-scan-text'>
                    Host Discovery - Local Network
                  </span>{' '}
                </span>
              </div>
            </div>
            <div className='completed-hdlocal'>
              <span className='completed-text-hdlocal'>In-Progress</span>
            </div>
            <div className='options-btn-hdlocal d-flex'>
              <Dropdown>
                <Dropdown.Toggle variant='none' id='dropdown-basic'>
                  Configure
                </Dropdown.Toggle>
              </Dropdown>
              <Dropdown>
                <Dropdown.Toggle variant='none' id='dropdown-basic'>
                  Export
                </Dropdown.Toggle>
              </Dropdown>
              <Dropdown>
                <Dropdown.Toggle
                  variant='none'
                  id='dropdown-basic'
                  className='text-white'
                >
                  Report
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item href='#'>
                    <img
                      src={Assets.Images.pdfIcon}
                      height={'22px'}
                      width={'22px'}
                    />
                    <span className='mx-2'>PDF</span>
                  </Dropdown.Item>
                  <Dropdown.Item href='#'>
                    <img
                      src={Assets.Images.ExcelIcon}
                      height={'22px'}
                      width={'22px'}
                    />
                    <span className='mx-2'>Excel</span>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </div>
        <div className='tabs-hdlocal'>
          <button onClick={navigateUiOverview}>Overview</button>
          <button onClick={navigateUiTohosts}>Hosts</button>
          <button onClick={navigateToVulnerabilities}>Vulnerabilities</button>
          <button onClick={navigateToRemediations}>Remediations</button>
          <button onClick={navigateToVprTopThreats}>VPR Top Threats</button>
          <button onClick={navigateToHistory}>History</button>
        </div>
        <div class='container hdlocal-content'>
          <div class='row'>
            <div class='col-sm-5'>
              <div class='card mb-3 hdlocal-left-card'>
                <div class='card-header-text'>Vulnerabilities</div>
                <div className='left-card-underline-hdlocal'></div>
                <div class='card-body vulnerabilities-chart'>
                  <div style={{ height: '360px' }}>
                    <div
                      style={{
                        position: 'absolute',
                        top: '52%',
                        left: '28%',
                        transform: 'translate(-50%, -50%)',
                      }}
                    >
                      <p style={{ textAlign: 'center' }}>
                        <span className='chartTitle'>Total</span> <br />
                        <span className='chartTotalValue'>
                          {pieChartTotalValue}
                        </span>
                      </p>
                    </div>
                    <Doughnut
                      data={pieChartData}
                      options={{
                        ...pieChartOptions,
                        onClick: handleChartClick,
                      }}
                      width={460}
                      height={460}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class='col-sm-7'>
              <div class='card mb-3 hdlocal-right-card'>
                <div class='card-header card-header-text'>Scan Details</div>
                <div className='right-card-underline-hdlocal'></div>
                <div class='card-body scan-details-row'>
                  <div className='scan-details-items'>
                    <span>Policy:</span>
                    <span>Host Discovery</span>
                  </div>
                  <div className='scan-details-items'>
                    <span>Status:</span>
                    <span>In Progress</span>
                  </div>
                  <div className='scan-details-items'>
                    <span>Severity:</span>
                    <span>CVSS v3.0</span>
                  </div>
                </div>
                <div class='card-body scan-details-row'>
                  <div className='scan-details-items'>
                    <span>Scanner:</span>
                    <span>Local Scanner</span>
                  </div>
                  <div className='scan-details-items'>
                    <span>Start:</span>
                    <span>Today at 2:00 PM</span>
                  </div>
                  <div className='scan-details-items'>
                    <span>End:</span>
                    <span>Today at 2:00 PM</span>
                  </div>
                </div>
                <div class='card-body scan-details-row'>
                  <div className='scan-details-items'>
                    <span>Elapsed:</span>
                    <span>A few seconds</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HostDiscoveryOverview;
