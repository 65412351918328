import React from 'react';
import './styles.scss';
import { useNavigate } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import Assets from '../../assets';
import Utils from '../../utils';
import SVG from '../../assets/svg';

const HostDiscoveryHistory = () => {
  const navigate = useNavigate();

  const navigateUiOverview = () => {
    navigate('/admin/new-scan/scan-templates/host-discovery/overview');
  };

  const navigateUiTohosts = () => {
    navigate('/admin/new-scan/scan-templates/host-discovery/hosts');
  };

  const navigateToVulnerabilities = () => {
    navigate('/admin/new-scan/scan-templates/host-discovery/vulnerabilities');
  };

  const navigateToHistory = () => {
    navigate('/admin/new-scan/scan-templates/host-discovery/history');
  };
  const navigateToRemediations = () => {
    navigate('/admin/new-scan/scan-templates/host-discovery/remediations');
  };

  const navigateToDashboard = () => {
    navigate('/admin/dashboard');
  };

  const navigateToVprTopThreats = () => {
    navigate('/admin/new-scan/scan-templates/host-discovery/vpr-top-threats');
  };

  const data = [
    {
      startTime: 'Today at 9:00 PM',
      lastModified: 'March 10, 2023',
      status: 'In progress',
    },
    {
      startTime: 'Today at 9:00 PM',
      lastModified: 'March 10, 2023',
      status: 'Completed',
    },
    {
      startTime: 'Today at 9:00 PM',
      lastModified: 'March 10, 2023',
      status: 'In progress',
    },
    {
      startTime: 'Today at 9:00 PM',
      lastModified: 'March 10, 2023',
      status: 'In progress',
    },
    {
      startTime: 'Today at 9:00 PM',
      lastModified: 'March 10, 2023',
      status: 'In progress',
    },
    {
      startTime: 'Today at 9:00 PM',
      lastModified: 'March 10, 2023',
      status: 'In progress',
    },
  ];

  return (
    <div>
      <div className='sub-header-hdhistory'>
        <div className='container'>
          <div className='sub-header-content'>
            <div className='sub-header-hdhistory-content'>
              <div>
                <SVG.ArrowBack
                  className='back-arrow'
                  onClick={navigateToDashboard}
                  alt='back arrow icon'
                />
                <span className='hdhistory-text'>
                  HOST DISCOVERY - LOCAL NETWORK
                </span>
                <div>
                  <span className='scan-type-hdhistory'>
                    <span>New Scan</span>{' '}
                    <img src={Assets.Icons.greaterIcon} alt='Greater Icon' />{' '}
                    <span>Scan Templates</span>
                    <img
                      src={Assets.Icons.greaterIcon}
                      alt='Greater Icon'
                    />{' '}
                    <span className='advanced-scan-text'>
                      Host Discovery - Local Network
                    </span>{' '}
                  </span>
                </div>
              </div>
              <div className='completed-hdhistory'>
                <span className='completed-text-hdhistory'>In-Progress</span>
              </div>
              <div className='options-btn-hdhistory d-flex'>
                <Dropdown>
                  <Dropdown.Toggle variant='none' id='dropdown-basic'>
                    Configure
                  </Dropdown.Toggle>
                </Dropdown>
                <Dropdown>
                  <Dropdown.Toggle variant='none' id='dropdown-basic'>
                    Export
                  </Dropdown.Toggle>
                </Dropdown>
                <Dropdown>
                  <Dropdown.Toggle
                    variant='none'
                    id='dropdown-basic'
                    className='text-white'
                  >
                    Report
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item href='#'>
                      <img
                        src={Assets.Images.pdfIcon}
                        height={'22px'}
                        width={'22px'}
                      />
                      <span className='mx-2'>PDF</span>
                    </Dropdown.Item>
                    <Dropdown.Item href='#'>
                      <img
                        src={Assets.Images.ExcelIcon}
                        height={'22px'}
                        width={'22px'}
                      />
                      <span className='mx-2'>Excel</span>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
          <div className='tabs-hdhistory'>
            <button onClick={navigateUiOverview}>Overview</button>
            <button onClick={navigateUiTohosts}>Hosts</button>
            <button onClick={navigateToVulnerabilities}>Vulnerabilities</button>
            <button onClick={navigateToRemediations}>Remediations</button>
            <button onClick={navigateToVprTopThreats}>VPR Top Threats</button>
            <button onClick={navigateToHistory}>History</button>
          </div>
        </div>
      </div>
      <div className='hdhistory-content'>
        <div className='hdhistory-subcontent'>
          <span className='hosts-title'>History</span>
          <p className='hosts-subtext'>
            Recent Expeditions: Your Vulnerability Searches
          </p>
          <div className='underline-hdhistory'></div>
          <div className='search-filter-hdhistory'>
            <div className='search-hdhistory'>
              <img src={Assets.Icons.search} alt='Search Icon' />
              <input type='text' placeholder='Search for hosts' />
            </div>
            <div className='approverStatus-dropdown'>
              <Dropdown>
                <Dropdown.Toggle variant='none' id='dropdown-basic'>
                  Approver Status
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item href='#'>
                    <input type='checkbox' height={'22px'} width={'22px'} />
                    <span className='mx-2'>Define Rule</span>
                  </Dropdown.Item>
                  <Dropdown.Item href='#'>
                    <input type='checkbox' height={'22px'} width={'22px'} />
                    <span className='mx-2'>Configure Rule</span>
                  </Dropdown.Item>
                  <Dropdown.Item href='#'>
                    <input type='checkbox' height={'22px'} width={'22px'} />
                    <span className='mx-2'>Set Values</span>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </div>
        <div>
          <table className='custom-table-hdhistory'>
            <thead>
              <tr>
                <th className='table-header'>
                  {' '}
                  <input type='checkbox' className='checkbox-hdhistory' />
                  Start Time
                </th>
                <th className='table-header'>
                  Last Modified
                  <img
                    src={Assets.Icons.upAndDownArrow}
                    className='ms-2'
                    alt='Back Arrow'
                  />
                </th>
                <th className='table-header'>Status</th>
              </tr>
            </thead>
            <tbody>
              {data.map((item, index) => (
                <tr
                  key={index}
                  className={index % 2 === 1 ? 'colored-row' : 'white-row'}
                >
                  <td className='table-data' style={{ width: '41%' }}>
                    <input type='checkbox' className='checkbox-hdhistory' />
                    {item.startTime}
                  </td>
                  <td className='table-data' style={{ width: '41%' }}>
                    {item.lastModified}{' '}
                  </td>
                  <td className='table-data' style={{ width: '35%' }}>
                    <div
                      className='badge-hdhistory'
                      style={{
                        ...Utils.dynamicBadgeStyle.getStatusBadgeStyle(
                          item.status
                        ),
                      }}
                    >
                      {item.status}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default HostDiscoveryHistory;
