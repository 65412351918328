// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container-nodata {
  align-items: center;
  justify-content: center;
}

.no-data {
  font-size: 30px;
}`, "",{"version":3,"sources":["webpack://./src/components/NoData/styles.scss"],"names":[],"mappings":"AAAA;EAEI,mBAAA;EACA,uBAAA;AAAJ;;AAGA;EACI,eAAA;AAAJ","sourcesContent":[".container-nodata{\r\n    \r\n    align-items: center;\r\n    justify-content: center;\r\n}\r\n\r\n.no-data{\r\n    font-size: 30px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
