import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import 'bootstrap/dist/js/bootstrap.js';
import './theme/variables.scss';
import AppRoutes from './routes';
import { Provider } from 'react-redux';
import { store, persistor } from './redux/store';
import { PersistGate } from 'redux-persist/integration/react';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-notifications/lib/notifications.css';
import Assets from './assets';

import { injectStore } from './services';
import { Helmet } from 'react-helmet';

injectStore(store);

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Helmet>
        <link rel="icon" type="image/svg+xml" href={Assets.Icons.favicon} />

        {/* Add more meta tags here */}
      </Helmet>
      <AppRoutes />
    </PersistGate>
  </Provider>
);

reportWebVitals();
