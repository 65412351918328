import React, { useState } from 'react';
import './styles.scss';
import Assets from '../../assets';
import { useNavigate } from 'react-router-dom';
import ProgressBar from '../../components/progressBar';
import { useForm } from 'react-hook-form';
import InputField from '../../components/inputField';
import Form from 'react-bootstrap/Form';

const AdvancedScan = () => {
  const navigate = useNavigate();

  const UiNavigate = () => {
    navigate('/admin/dashboard');
  };

  const data = ['General', 'Schedule', 'Notification'];

  const { register, handleSubmit, errors } = useForm({ mode: 'onChange' });

  const [schedule, setSchedule] = useState(false);
  const [general, setGeneral] = useState(true);
  const [switchEnabled, setSwitchEnabled] = useState(false);

  const onSubmit = () => {
    setSchedule(!schedule);
    setGeneral(false);
  };
  return (
    <div className='sub-header-advanced'>
      <div className='container'>
        <div className='sub-header-content'>
          <div>
            <img
              src={Assets.Icons.backArrow}
              alt='back arrow icon'
              className='back-arrow'
              onClick={UiNavigate}
            />
            <span className='newscan-text'>ADVANCED SCAN</span>
            <div>
              <span className='scan-type-adv'>
                <span>New Scan</span>{' '}
                <img src={Assets.Icons.greaterIcon} alt='Greater Icon' />{' '}
                <span>Scan Templates</span>
                <img src={Assets.Icons.greaterIcon} alt='Greater Icon' />{' '}
                <span className='advanced-scan-text'>AdvancedScan</span>{' '}
              </span>
            </div>
          </div>
        </div>
        <div className='tabs-adv'>
          <button>Settings</button>
          <button>Schedule</button>
          <button>Plugins</button>
        </div>
        <div class='container advscan-content'>
          <div class='row'>
            <div class='col-4'>
              <div className='adv-left-content'>
                <div class='d-grid gap-2 '>
                  <button class='btn btn-primary' type='button'>
                    Basic
                  </button>
                  <button class='btn btn-primary' type='button'>
                    Discovery
                  </button>
                  <button class='btn btn-primary' type='button'>
                    Assessment
                  </button>
                  <button class='btn btn-primary' type='button'>
                    Report
                  </button>
                  <button class='btn btn-primary' type='button'>
                    Advanced
                  </button>
                </div>
              </div>
            </div>
            <div class='col-8'>
              {general && (
                <div className='adv-right-content'>
                  <div className='progress-adv'>
                    <ProgressBar />
                    <div>
                      <span className='mx-2'>General</span>
                      <span className='mx-2'>Schedule</span>
                      <span className='mx-2'>Notification</span>
                    </div>
                  </div>
                  <span className='basic-details-text-adv'>Basic Details</span>
                  <hr className='line-adv' />
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className='form-row-one'>
                      <div className='adv-form-name'>
                        <span>Name</span>
                        <div>
                          <InputField
                            className='isName'
                            type='text'
                            placeholder='Name'
                            name='name'
                            value=''
                            register={register({
                              required: true,
                              pattern: '^[a-zA-Z]*[s]*[a-zA-Z]*[s]*[a-zA-Z]*$',
                            })}
                            error={errors.name}
                            messages={{
                              required: '*Name is required',
                              pattern: 'Name should contain only alphabets',
                            }}
                          />
                        </div>
                      </div>
                      <div className='adv-form-folder'>
                        <span>Folder</span>
                        <select>
                          <option>Select folder</option>
                        </select>
                      </div>
                    </div>
                    <div className='form-row-two'>
                      <div className='adv-form-description'>
                        <span>Description</span>
                        <textarea type='text' placeholder='Factory' />
                      </div>
                      <div className='adv-form-targets'>
                        <span>Targets</span>
                        <textarea type='text' placeholder='Targets' />
                      </div>
                    </div>
                    <div className='form-row-three'>
                      <div className='adv-form-uploadtargets'>
                        <span>Upload Targets</span>
                        <div className='adv-file-upload-box'>
                          <img
                            src={Assets.Icons.uploadCloud}
                            height={38}
                            width={47}
                          />
                          <span className='upload-box-text'>
                            Drag your files here to start uploading or{' '}
                            <button>Upload file</button>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className='form-row-four'>
                      <div className='adv-form-postProcessing'>
                        <span className='post-text'>Post-Processing</span>
                        <hr className='post-line-adv' />
                      </div>
                      <div className='live-results-adv'>
                        <input
                          type='checkbox'
                          className='adv-liveresults-input'
                        />
                        <span className='live-results-text-adv ms-3'>
                          Live results
                        </span>
                        <div className='live-results-div mt-4'>
                          <span className='live-results-desc'>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor incididunt ut labore et
                            dolore magna aliqua. Ut enim.{' '}
                          </span>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              )}
              {schedule && (
                <div className='adv-right-content-schedule'>
                  <div className='progress-adv'>
                    <ProgressBar />
                    <div>
                      <span className='mx-2'>General</span>
                      <span className='mx-2'>Schedule</span>
                      <span className='mx-2'>Notification</span>
                    </div>
                  </div>
                  <div className='adv-enable'>
                    <span className='me-3'>Enable</span>
                    <Form>
                      <Form.Check
                        type='switch'
                        id='custom-switch'
                        checked={switchEnabled}
                        onChange={() => setSwitchEnabled(!switchEnabled)}
                      />
                    </Form>
                  </div>
                  <hr className='adv-enable-hr' />
                  {switchEnabled && (
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className='enable-first-row-adv'>
                        <div>
                          <label>Frequency</label>
                          <InputField type='text' placeholder='Name' />
                        </div>
                        <div>
                          <label>Starts</label>
                          <InputField type='text' placeholder='Time' />
                        </div>
                        <div>
                          <label></label>
                          <InputField type='text' placeholder='Select date' />
                        </div>
                        <br />
                      </div>
                      <div className='enable-second-row-adv'>
                        <div>
                          <label>Time Zone</label>
                          <InputField type='text' placeholder='Time Zone' />
                        </div>
                        <div>
                          <label>Summary</label>
                          <InputField type='text' placeholder='Lorem ipsum' />
                        </div>

                        <br />
                      </div>
                      <br />
                    </form>
                  )}
                </div>
              )}
              <div className='adv-action-btns mt-3 mb-3'>
                <button className='mx-4'>Cancel</button>
                <button type='submit'>Next</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdvancedScan;
