import React from 'react';

const ChangePasswordIcon = ({ active }) => (
  <svg
    width='21'
    height='21'
    viewBox='0 0 21 21'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M9.06156 4.83878H8.15263V3.172C8.15263 1.79173 6.93114 0.671997 5.42544 0.671997C3.91974 0.671997 2.69826 1.79173 2.69826 3.172V4.83878H1.78933C1.28937 4.83878 0.880005 5.21476 0.880005 5.672V9.83878C0.880005 10.2978 1.28938 10.672 1.78933 10.672H9.06165C9.5616 10.672 9.97098 10.2978 9.97098 9.83878V5.672C9.97098 5.21477 9.5616 4.83878 9.06165 4.83878H9.06156ZM4.51651 3.172C4.51651 2.71128 4.92285 2.33878 5.42544 2.33878C5.92803 2.33878 6.33437 2.71127 6.33437 3.172V4.83878H4.51651V3.172Z'
      fill={active ? `#ffffff` : `#8A92A6`}
    />
    <path
      d='M15.4256 9.83875C15.4256 10.2967 15.0162 10.672 14.5163 10.672H12.6984C12.1985 10.672 11.7891 10.2967 11.7891 9.83875V8.17196C11.7891 7.71366 12.1985 7.33875 12.6984 7.33875H14.5163C15.0163 7.33875 15.4256 7.71367 15.4256 8.17196V9.83875Z'
      fill={active ? `#ffffff` : `#8A92A6`}
    />
    <path
      d='M20.88 9.83875C20.88 10.2967 20.4707 10.672 19.9707 10.672H18.1529C17.6529 10.672 17.2435 10.2967 17.2435 9.83875V8.17196C17.2435 7.71366 17.6529 7.33875 18.1529 7.33875H19.9707C20.4707 7.33875 20.88 7.71367 20.88 8.17196V9.83875Z'
      fill={active ? `#ffffff` : `#8A92A6`}
    />
    <path
      d='M15.4256 14.8387C15.4256 15.2967 15.0162 15.672 14.5163 15.672H12.6984C12.1985 15.672 11.7891 15.2967 11.7891 14.8387V13.172C11.7891 12.7137 12.1985 12.3387 12.6984 12.3387H14.5163C15.0163 12.3387 15.4256 12.7137 15.4256 13.172V14.8387Z'
      fill={active ? `#ffffff` : `#8A92A6`}
    />
    <path
      d='M20.88 14.8387C20.88 15.2967 20.4707 15.672 19.9707 15.672H18.1529C17.6529 15.672 17.2435 15.2967 17.2435 14.8387V13.172C17.2435 12.7137 17.6529 12.3387 18.1529 12.3387H19.9707C20.4707 12.3387 20.88 12.7137 20.88 13.172V14.8387Z'
      fill={active ? `#ffffff` : `#8A92A6`}
    />
    <path
      d='M15.4256 19.8387C15.4256 20.2967 15.0162 20.672 14.5163 20.672H12.6984C12.1985 20.672 11.7891 20.2967 11.7891 19.8387V18.172C11.7891 17.7137 12.1985 17.3387 12.6984 17.3387H14.5163C15.0163 17.3387 15.4256 17.7137 15.4256 18.172V19.8387Z'
      fill={active ? `#ffffff` : `#8A92A6`}
    />
    <path
      d='M9.97128 14.8387C9.97128 15.2967 9.5619 15.672 9.06196 15.672H7.2441C6.74415 15.672 6.33478 15.2967 6.33478 14.8387V13.172C6.33478 12.7137 6.74416 12.3387 7.2441 12.3387H9.06196C9.56191 12.3387 9.97128 12.7137 9.97128 13.172V14.8387Z'
      fill={active ? `#ffffff` : `#8A92A6`}
    />
    <path
      d='M9.97128 19.8387C9.97128 20.2967 9.5619 20.672 9.06196 20.672H7.2441C6.74415 20.672 6.33478 20.2967 6.33478 19.8387V18.172C6.33478 17.7137 6.74416 17.3387 7.2441 17.3387H9.06196C9.56191 17.3387 9.97128 17.7137 9.97128 18.172V19.8387Z'
      fill={active ? `#ffffff` : `#8A92A6`}
    />
    <path
      d='M20.88 19.8387C20.88 20.2967 20.4707 20.672 19.9707 20.672H18.1529C17.6529 20.672 17.2435 20.2967 17.2435 19.8387V18.172C17.2435 17.7137 17.6529 17.3387 18.1529 17.3387H19.9707C20.4707 17.3387 20.88 17.7137 20.88 18.172V19.8387Z'
      fill={active ? `#ffffff` : `#8A92A6`}
    />
  </svg>
);

export default ChangePasswordIcon;
