import React from 'react';

const LogoutIcon = ({ active }) => (
  <svg
    width='21'
    height='21'
    viewBox='0 0 21 21'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M20.26 11.5904C20.2658 13.7937 19.3855 15.9236 17.7838 17.5813C16.1819 19.2392 13.9686 20.3112 11.5573 20.5971C9.14639 20.8828 6.70294 20.363 4.68421 19.1344C2.66565 17.906 1.21031 16.0532 0.590469 13.9228C-0.0294295 11.7924 0.228535 9.53017 1.3161 7.5597C2.40392 5.58891 4.24675 4.04498 6.49973 3.21664C6.82603 3.11733 7.18549 3.17643 7.45261 3.373C7.71952 3.56957 7.8569 3.87641 7.81633 4.18611C7.77577 4.49579 7.56309 4.76508 7.2527 4.89972C4.94597 5.75016 3.20871 7.52642 2.5541 9.70374C1.89949 11.8811 2.40238 14.2105 3.9142 16.0039C5.42623 17.797 7.77444 18.8491 10.2646 18.8491C12.7551 18.8491 15.1034 17.797 16.6151 16.0039C18.1271 14.2106 18.63 11.8812 17.9754 9.70374C17.3208 7.52642 15.5836 5.75016 13.2766 4.89972C13.0142 4.81971 12.7997 4.64528 12.6841 4.41723C12.5682 4.18918 12.5617 3.92803 12.6657 3.6953C12.7699 3.46278 12.9752 3.27941 13.2336 3.18884C13.4917 3.09828 13.7799 3.10822 14.0296 3.21667C15.8729 3.88843 17.4515 5.04532 18.5617 6.5381C19.6721 8.03088 20.2636 9.79065 20.2596 11.5909L20.26 11.5904ZM10.2601 10.9991C10.5264 10.9991 10.7819 10.9032 10.9701 10.7327C11.1584 10.5621 11.2641 10.3306 11.2641 10.0893V1.58339C11.2641 1.25828 11.0728 0.957951 10.7621 0.795495C10.4515 0.633039 10.0687 0.633039 9.75814 0.795495C9.44753 0.957951 9.25614 1.25829 9.25614 1.58339V10.0893C9.25614 10.3306 9.36192 10.5621 9.55017 10.7327C9.73841 10.9032 9.9939 10.9991 10.2601 10.9991Z'
      fill={active ? `#ffffff` : `#8A92A6`}
    />
  </svg>
);

export default LogoutIcon;
