// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.side-bar-container {
  position: fixed;
  left: 0;
  background-color: #0e0129;
  width: 91px;
  height: 100%;
  top: 75px;
}

.sidebar-items {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.folder-num {
  font-family: "Rubik_Light";
  font-size: 12px;
  font-weight: 400;
  color: #ffffff;
  margin-top: 10px;
}`, "",{"version":3,"sources":["webpack://./src/components/sidebar/styles.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,OAAA;EACA,yBAAA;EACA,WAAA;EACA,YAAA;EACA,SAAA;AACF;;AACA;EACE,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;AAEF;;AAAA;EACE,0BAAA;EACA,eAAA;EACA,gBAAA;EACA,cAAA;EACA,gBAAA;AAGF","sourcesContent":[".side-bar-container {\r\n  position: fixed;\r\n  left: 0;\r\n  background-color: #0e0129;\r\n  width: 91px;\r\n  height: 100%;\r\n  top: 75px;\r\n}\r\n.sidebar-items {\r\n  display: flex;\r\n  flex-direction: column;\r\n  justify-content: center;\r\n  align-items: center;\r\n}\r\n.folder-num {\r\n  font-family: 'Rubik_Light';\r\n  font-size: 12px;\r\n  font-weight: 400;\r\n  color: #ffffff;\r\n  margin-top: 10px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
