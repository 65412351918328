// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-error {
  font-size: 12px !important;
  color: #f90500 !important;
  font-family: "Rubik_Regular";
}`, "",{"version":3,"sources":["webpack://./src/components/ErrorMessage/ErrorMessage.scss"],"names":[],"mappings":"AAAA;EACE,0BAAA;EACA,yBAAA;EACA,4BAAA;AACF","sourcesContent":[".form-error {\r\n  font-size: 12px !important;\r\n  color: #f90500 !important;\r\n  font-family: 'Rubik_Regular';\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
