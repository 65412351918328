// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fields-container {
  background-color: #fff;
  border-radius: 10px;
  padding: 30px;
  width: 40%;
  margin: auto;
}

.title {
  text-align: center;
  margin-top: 50px;
}

.back-icon {
  width: 30px;
  height: 30px;
  margin-right: 10px;
  cursor: pointer;
}

.box-container {
  background-color: #fff;
  border-radius: 10px;
  padding: 30px;
  width: 95%;
  margin-top: 30px;
  box-shadow: 0px 0px 5px 2px rgb(193, 193, 193);
}

.label {
  margin: 0px;
}

.download-icon {
  height: 20px;
  width: 20px;
  margin-left: 10px;
}

.user-types-container-2 {
  width: 100%;
  border-radius: 3px;
  align-items: center;
  display: flex;
  background-color: white;
  margin-top: 10px;
}

.select-user-type {
  width: 100%;
  height: 40px;
  font-family: "Rubik_Regular";
  font-style: normal;
  font-size: 14px;
}

.react-select__control {
  border: 1px solid #ccc;
  border-radius: 4px;
}`, "",{"version":3,"sources":["webpack://./src/screens/addUserTypes/styles.scss"],"names":[],"mappings":"AAAA;EACI,sBAAA;EACA,mBAAA;EACA,aAAA;EACA,UAAA;EACA,YAAA;AACJ;;AAEA;EACI,kBAAA;EACA,gBAAA;AACJ;;AAEA;EACI,WAAA;EACA,YAAA;EACA,kBAAA;EACA,eAAA;AACJ;;AAEA;EACI,sBAAA;EACA,mBAAA;EACA,aAAA;EACA,UAAA;EACA,gBAAA;EAEA,8CAAA;AACJ;;AAEA;EACI,WAAA;AACJ;;AAEA;EACI,YAAA;EACA,WAAA;EACA,iBAAA;AACJ;;AAGA;EACI,WAAA;EACA,kBAAA;EACA,mBAAA;EACA,aAAA;EACA,uBAAA;EACA,gBAAA;AAAJ;;AAGA;EACI,WAAA;EACA,YAAA;EACA,4BAAA;EACA,kBAAA;EACA,eAAA;AAAJ;;AAGA;EACI,sBAAA;EACA,kBAAA;AAAJ","sourcesContent":[".fields-container{\r\n    background-color: #fff;\r\n    border-radius: 10px;\r\n    padding: 30px;\r\n    width: 40%;\r\n    margin: auto;\r\n}\r\n\r\n.title{\r\n    text-align: center;\r\n    margin-top: 50px ;\r\n}\r\n\r\n.back-icon{\r\n    width: 30px;\r\n    height: 30px;\r\n    margin-right: 10px;\r\n    cursor: pointer;\r\n}\r\n\r\n.box-container{\r\n    background-color: #fff;\r\n    border-radius: 10px;\r\n    padding: 30px;\r\n    width: 95%;\r\n    margin-top: 30px;\r\n    -webkit-box-shadow: 0 0 10px #fff;\r\n    box-shadow: 0px 0px 5px 2px rgb(193, 193, 193);\r\n}\r\n\r\n.label{\r\n    margin: 0px;\r\n}\r\n\r\n.download-icon{\r\n    height: 20px;\r\n    width: 20px;\r\n    margin-left: 10px;\r\n}\r\n\r\n\r\n.user-types-container-2{\r\n    width: 100%;\r\n    border-radius: 3px;\r\n    align-items: center;\r\n    display: flex;\r\n    background-color: white;\r\n    margin-top: 10px;\r\n}\r\n\r\n.select-user-type{\r\n    width: 100%;\r\n    height: 40px;\r\n    font-family: 'Rubik_Regular';\r\n    font-style: normal;\r\n    font-size: 14px;\r\n}\r\n\r\n.react-select__control {\r\n    border: 1px solid #ccc;\r\n    border-radius: 4px;\r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
