// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card-box {
  background-color: #fff;
  border-radius: 10px;
  font-family: "Rubik";
  box-shadow: 4px 4px 6px rgba(0, 0, 0, 0.1);
}
.card-box .seperator {
  margin: 8px 0;
}
.card-box .card-heading {
  letter-spacing: -0.2px;
  color: #667085;
  font-weight: 200;
  font-size: 17px;
  margin-bottom: 0;
}
.card-box .ongoinghead {
  letter-spacing: -0.2px;
  color: #111827;
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 0;
  font-family: "Rubik";
}
.card-box .black-text {
  color: #222;
  font-size: 14px;
}`, "",{"version":3,"sources":["webpack://./src/components/Card/style.scss"],"names":[],"mappings":"AAAA;EACE,sBAAA;EACA,mBAAA;EACA,oBAAA;EACA,0CAAA;AACF;AACE;EACE,aAAA;AACJ;AAEE;EACE,sBAAA;EACA,cAAA;EACA,gBAAA;EACA,eAAA;EACA,gBAAA;AAAJ;AAGE;EACE,sBAAA;EACA,cAAA;EACA,gBAAA;EACA,eAAA;EACA,gBAAA;EACA,oBAAA;AADJ;AAIE;EACE,WAAA;EACA,eAAA;AAFJ","sourcesContent":[".card-box {\r\n  background-color: #fff;\r\n  border-radius: 10px;\r\n  font-family: 'Rubik';\r\n  box-shadow: 4px 4px 6px rgba(0, 0, 0, 0.1);\r\n\r\n  .seperator {\r\n    margin: 8px 0;\r\n  }\r\n\r\n  .card-heading {\r\n    letter-spacing: -0.2px;\r\n    color: #667085;\r\n    font-weight: 200;\r\n    font-size: 17px;\r\n    margin-bottom: 0;\r\n  }\r\n\r\n  .ongoinghead {\r\n    letter-spacing: -0.2px;\r\n    color: #111827;\r\n    font-weight: 600;\r\n    font-size: 20px;\r\n    margin-bottom: 0;\r\n    font-family: 'Rubik';\r\n  }\r\n\r\n  .black-text {\r\n    color: #222;\r\n    font-size: 14px;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
