import DashboardIcon from './dashboard';
import LogoutIcon from './logout';
import ChangePasswordIcon from './change_password';
import CSVIcon from './csv';
import UserManagementIcon from './user_management';
import { ReactComponent as PauseIcon } from './pause.svg';
import { ReactComponent as DeleteIcon } from './delete.svg';
import { ReactComponent as EditIcon } from './edit.svg';
import { ReactComponent as RefreshIcon } from './refresh.svg';
import { ReactComponent as StopIcon } from './stop.svg';
import { ReactComponent as CancelIcon } from './cancel.svg';
import { ReactComponent as ArrowBack } from './arrow_back.svg';
import { ReactComponent as GreaterIcon } from './greater_icon.svg';

const SVG = {
  DashboardIcon,
  LogoutIcon,
  ChangePasswordIcon,
  CSVIcon,
  UserManagementIcon,
  Pause: PauseIcon,
  Delete: DeleteIcon,
  Edit: EditIcon,
  Refresh: RefreshIcon,
  Stop: StopIcon,
  Cancel: CancelIcon,
  ArrowBack: ArrowBack,
  GreaterIcon: GreaterIcon,
};

export default SVG;
