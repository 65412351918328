import React from "react";
import classes from "./styles.module.scss";

const NormalCheckbox = (props) => {
  const { name, checked, onChange, label, disabled = false } = props;
  return (
    <div className="row">
      <div className="col-2">
        <input
          name={name}
          checked={checked}
          onChange={onChange}
          disabled={disabled}
          className={classes.checkboxStyle}
          type="checkbox"
        />
      </div>
      <div className="col-10">{label && <label className={`${classes.checkboxLabel}`}>{label}</label>}</div>
    </div>
  );
};

export default NormalCheckbox;
