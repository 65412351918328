// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.w-75 {
  width: 85% !important;
}

.progress {
  width: 222px;
  height: 15px;
  border-radius: 13px;
}

.progress-bar {
  background-color: #0094ff;
}`, "",{"version":3,"sources":["webpack://./src/components/progressBar/styles.scss"],"names":[],"mappings":"AAAA;EACE,qBAAA;AACF;;AACA;EACE,YAAA;EACA,YAAA;EACA,mBAAA;AAEF;;AACA;EACE,yBAAA;AAEF","sourcesContent":[".w-75 {\r\n  width: 85% !important;\r\n}\r\n.progress {\r\n  width: 222px;\r\n  height: 15px;\r\n  border-radius: 13px;\r\n}\r\n\r\n.progress-bar {\r\n  background-color: #0094ff;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
