import React from 'react';
import './styles.scss';
import Assets from '../../assets';

const Sidebar = () => {
  return (
    <div id='sideBar' className='side-bar-container'>
      <div className='sidebar-items'>
        <img src={Assets.Images.folder} alt='folder icon' className='mt-5' />
        <span className='folder-num'>Folder 1</span>
        <img src={Assets.Images.folder} alt='folder icon' className='mt-4' />
        <span className='folder-num'>Folder 2</span>
        <img src={Assets.Images.folder} alt='folder icon' className='mt-4' />
        <span className='folder-num'>Folder 3</span>
      </div>
    </div>
  );
};

export default Sidebar;
