// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#main {
  transition: margin-left 0.5s;
  margin-left: 240px;
  margin-top: 10px;
  font-family: "Inter";
}

.footer-item {
  position: absolute;
  bottom: 0;
  display: flex;
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/layout/layout.scss"],"names":[],"mappings":"AAAA;EACE,4BAAA;EACA,kBAAA;EACA,gBAAA;EACA,oBAAA;AACF;;AAEA;EACE,kBAAA;EACA,SAAA;EACA,aAAA;EACA,WAAA;AACF","sourcesContent":["#main {\r\n  transition: margin-left 0.5s;\r\n  margin-left: 240px;\r\n  margin-top: 10px;\r\n  font-family: 'Inter';\r\n}\r\n\r\n.footer-item {\r\n  position: absolute;\r\n  bottom: 0;\r\n  display: flex;\r\n  width: 100%;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
