// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user_input_forgotPassword {
  width: 345px;
}

.forgot_password_title {
  font-weight: 500 !important;
}

.sendOtp_button {
  width: 350px;
  height: 45px;
  border-radius: 4px;
  padding: 13px 129px 13px 126px;
  border: none;
  background-color: #0094ff;
  color: #ffffff;
  line-height: 16px;
  font-size: 15px;
  font-weight: 600;
}`, "",{"version":3,"sources":["webpack://./src/screens/forgotPassword/styles.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;AACF;;AACA;EACE,2BAAA;AAEF;;AAAA;EACE,YAAA;EACA,YAAA;EACA,kBAAA;EACA,8BAAA;EAEA,YAAA;EACA,yBAAA;EACA,cAAA;EACA,iBAAA;EACA,eAAA;EACA,gBAAA;AAEF","sourcesContent":[".user_input_forgotPassword {\r\n  width: 345px;\r\n}\r\n.forgot_password_title {\r\n  font-weight: 500 !important;\r\n}\r\n.sendOtp_button {\r\n  width: 350px;\r\n  height: 45px;\r\n  border-radius: 4px;\r\n  padding: 13px 129px 13px 126px;\r\n  // margin-top: 24px;\r\n  border: none;\r\n  background-color: #0094ff;\r\n  color: #ffffff;\r\n  line-height: 16px;\r\n  font-size: 15px;\r\n  font-weight: 600;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
