import React, { useState } from 'react';
import './styles.scss';
import { useNavigate } from 'react-router-dom';
import ProgressBar from '../../components/progressBar';
import Assets from '../../assets';
import SVG from '../../assets/svg';
import ModalBox from '../../components/FilterModal/index';
import Dropdown from 'react-bootstrap/Dropdown';
import GeneratePdfModal from '../../components/generatePdfModal';

const HostDiscoveryHosts = () => {
  const navigate = useNavigate();

  const navigateUiOverview = () => {
    navigate('/admin/new-scan/scan-templates/host-discovery/overview');
  };

  const navigateUiTohosts = () => {
    navigate('/admin/new-scan/scan-templates/host-discovery/hosts');
  };

  const navigateToVulnerabilities = () => {
    navigate('/admin/new-scan/scan-templates/host-discovery/vulnerabilities');
  };

  const navigateToHistory = () => {
    navigate('/admin/new-scan/scan-templates/host-discovery/history');
  };
  const navigateToRemediation = () => {
    navigate('/admin/new-scan/scan-templates/host-discovery/remediation');
  };
  const navigateToDashboard = () => {
    navigate('/admin/dashboard');
  };
  const navigateToVprTopThreats = () => {
    navigate('/admin/new-scan/scan-templates/host-discovery/vpr-top-threats');
  };

  const navigateToRemediations = () => {
    navigate('/admin/new-scan/scan-templates/host-discovery/remediations');
  };

  const data = [
    {
      hosts: '125.235.2.697',
      ports: '135, 235, 146, 684, 231, 123, 423, 215, 452, 124, 245',
    },
    {
      hosts: '125.235.2.697',
      ports:
        '135, 235, 146, 684, 231, 123, 423, 215, 452, 124, 245, 5424, 4295, 6267, 5161',
    },
    {
      hosts: '125.235.2.697',
      ports: '135, 235, 146, 684, 231, 123, 423, 215, 452, 124, 245',
      actions: 'Action',
    },
    {
      hosts: '125.235.2.697',
      ports:
        '135, 235, 146, 684, 231, 123, 423, 215, 452, 124, 245, 5424, 4295, 6267, 5161',
      actions: 'Action',
    },
    {
      hosts: '125.235.2.697',
      ports: '135, 235, 146, 684, 231, 123, 423, 215, 452, 124, 245',
      actions: 'Action',
    },
    {
      hosts: '125.235.2.697',
      ports:
        '135, 235, 146, 684, 231, 123, 423, 215, 452, 124, 245, 5424, 4295, 6267, 5161',
      actions: 'Action',
    },
    {
      hosts: '125.235.2.697',
      ports: '135, 235, 146, 684, 231, 123, 423, 215, 452, 124, 245',
      actions: 'Action',
    },
    {
      hosts: '125.235.2.697',
      ports:
        '135, 235, 146, 684, 231, 123, 423, 215, 452, 124, 245, 5424, 4295, 6267, 5161',
      actions: 'Action',
    },
    {
      hosts: '125.235.2.697',
      ports: '135, 235, 146, 684, 231, 123, 423, 215, 452, 124, 245',
      actions: 'Action',
    },
  ];

  const [modalShow, setModalShow] = useState(false);
  const [pdfModalShow, setPdfModalShow] = useState(false);

  const [hostName, setHostName] = useState('');

  const updateHostName = (newHostName) => {
    setHostName(newHostName);
  };

  return (
    <div>
      <div className='sub-header-hdhost'>
        <div className='container'>
          <div className='sub-header-content'>
            <div className='sub-header-hdhost-content'>
              <div>
                <SVG.ArrowBack
                  className='back-arrow'
                  onClick={navigateToDashboard}
                  alt='back arrow icon'
                />
                <span className='hdhost-text'>
                  HOST DISCOVERY - LOCAL NETWORK
                </span>
                <div>
                  <span className='scan-type-hdhost'>
                    <span>New Scan</span>{' '}
                    <img src={Assets.Icons.greaterIcon} alt='Greater Icon' />{' '}
                    <span>Scan Templates</span>
                    <img
                      src={Assets.Icons.greaterIcon}
                      alt='Greater Icon'
                    />{' '}
                    <span className='advanced-scan-text'>
                      Host Discovery - Local Network
                    </span>{' '}
                  </span>
                </div>
              </div>
              <div className='completed-hdhost'>
                <span className='completed-text-hdhost'>In-Progress</span>
              </div>
              <div className='options-btn-hdhost d-flex'>
                <Dropdown>
                  <Dropdown.Toggle variant='none' id='dropdown-basic'>
                    Configure
                  </Dropdown.Toggle>
                </Dropdown>
                <Dropdown>
                  <Dropdown.Toggle variant='none' id='dropdown-basic'>
                    Export
                  </Dropdown.Toggle>
                </Dropdown>
                <Dropdown>
                  <Dropdown.Toggle
                    variant='none'
                    id='dropdown-basic'
                    className='text-white'
                  >
                    Report
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item
                      href='#'
                      onClick={() => setPdfModalShow(true)}
                    >
                      <img
                        src={Assets.Images.pdfIcon}
                        height={'22px'}
                        width={'22px'}
                      />
                      <span className='mx-2'>PDF</span>
                    </Dropdown.Item>
                    <Dropdown.Item href='#'>
                      <img
                        src={Assets.Images.ExcelIcon}
                        height={'22px'}
                        width={'22px'}
                      />
                      <span className='mx-2'>Excel</span>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <div>
                <GeneratePdfModal
                  show={pdfModalShow}
                  onHide={() => setPdfModalShow(false)}
                />
              </div>
            </div>
          </div>
          <div className='tabs-hdhost'>
            <button onClick={navigateUiOverview}>Overview</button>
            <button onClick={navigateUiTohosts}>Hosts</button>
            <button onClick={navigateToVulnerabilities}>Vulnerabilities</button>
            <button onClick={navigateToRemediations}>Remediations</button>
            <button onClick={navigateToVprTopThreats}>VPR Top Threats</button>
            <button onClick={navigateToHistory}>History</button>
          </div>
        </div>
      </div>
      <div className='hdhost-content'>
        <div className='hdhost-subcontent'>
          <span className='hosts-title'>Hosts</span>
          <p className='hosts-subtext'>Discover a Wide Range of Hosts</p>
          <div className='underline-hdhost'></div>
          <div className='search-filter-hdhost'>
            <div className='search-hdhost'>
              <img src={Assets.Icons.search} alt='Search Icon' />
              <input type='text' placeholder='Search for hosts' />
            </div>
            <div className='filter-hdhost'>
              <button onClick={() => setModalShow(true)}>
                {' '}
                <img src={Assets.Icons.filterIcon} alt='Filter lines' />
                Filters
              </button>
            </div>
            {hostName && !modalShow && (
              <div className='selectedHostName'>
                {hostName !== null ? (
                  <div>
                    <span>Hostname = {hostName}</span>
                    <span className='ms-3'>X</span>
                  </div>
                ) : (
                  ''
                )}
              </div>
            )}
            <div>
              <ModalBox
                show={modalShow}
                onHide={() => setModalShow(false)}
                onHostNameChange={updateHostName}
              />
            </div>
          </div>
        </div>
        <div>
          <table className='custom-table-hdhost'>
            <thead>
              <tr>
                <th className='table-header' style={{ width: '21%' }}>
                  {' '}
                  <input type='checkbox' className='checkbox-hdhost' />
                  Hosts
                </th>
                <th className='table-header-hdhost' style={{ width: '50%' }}>
                  Ports
                </th>
                <th className='table-header-hdhost' style={{ width: '36%' }}>
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              {data.map((item, index) => (
                <tr
                  key={index}
                  className={index % 2 === 1 ? 'colored-row' : 'white-row'}
                >
                  <td className='table-data-hdhost' style={{ width: '25%' }}>
                    <input type='checkbox' className='checkbox-hdhost' />
                    {item.hosts}
                  </td>
                  <td className='table-data-hdhost' style={{ width: '60%' }}>
                    {item.ports}
                  </td>
                  <td className='table-data-hdhost'>
                    <div className='d-flex'>
                      <SVG.Cancel className='action-icon' />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <br />
    </div>
  );
};

export default HostDiscoveryHosts;
