import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import './styles.scss';
import Assets from '../../assets';

function ModalBox(props) {
  const [hostName, setHostName] = useState('');

  const handleHostNameChange = (newHostName) => {
    setHostName(newHostName);
    props.onHostNameChange(newHostName);
  };

  console.log(hostName, 13);

  return (
    <Modal
      {...props}
      size='lg'
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Header>
        <Modal.Title id='contained-modal-title-vcenter'>Filters</Modal.Title>
        <hr className='hr-line-filter' />
      </Modal.Header>
      <Modal.Body>
        <div className='d-flex filter-query'>
          <span className='mx-2 mt-2'>Match</span>
          <select className='mx-3 filter-select-dropdown'>
            <option>All</option>
          </select>
          <span className='mx-2 mt-2'>Of the following</span>
        </div>
        <div className='d-flex justify-content-around mt-4 me-5'>
          <div className='filter-option-dropdown'>
            <select
              value={hostName}
              onChange={(e) => {
                setHostName(e.target.value);
                handleHostNameChange(e.target.value); // Call the callback function
              }}
            >
              <option>Hostname</option>
              <option>125.235.2.697</option>
              <option>125.235.2.697</option>
            </select>
            {console.log(hostName, 'hostName')}
          </div>
          <div className='filter-option-dropdown'>
            <select>
              <option>Is equal to</option>
            </select>
          </div>
          <div className='filter-option-dropdown'>
            <select>
              <option>Text</option>
            </select>
          </div>
        </div>
        <div className='d-flex justify-content-around mt-4'>
          <div className='filter-option-dropdown'>
            <select>
              <option>Hostname</option>
            </select>
          </div>
          <div className='filter-option-dropdown'>
            <select>
              <option>Is equal to</option>
            </select>
          </div>
          <div className='filter-option-dropdown'>
            <select>
              <option>Text</option>
            </select>
          </div>
          <div className='delete-icon'>
            <img src={Assets.Icons.deleteIcon} height={16} width={16} />
          </div>
        </div>
        <div className='add-new-filter'>
          <div className='add-new-btn'>+</div>
          <span>Add new</span>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className='footer-buttons'>
          <button className='mx-2'>Clear All</button>
          <button className='mx-2' onClick={props.onHide}>
            Cancel
          </button>
          <button className='mx-2' onClick={props.onHide}>
            Apply
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

export default ModalBox;
