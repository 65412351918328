import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import './styles.scss';
import Dropdown from 'react-bootstrap/Dropdown';

function GeneratePdfModal(props) {
  const [showCustomModal, setShowCustomModal] = useState(false);
  return (
    <Modal
      {...props}
      size={showCustomModal ? 'lg' : 'sm'}
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Header>
        <Modal.Title id='contained-modal-title-vcenter'>
          <div style={{ width: '420px' }}>Generate PDF Report</div>
        </Modal.Title>
        <hr className='hr-line-filter' />
      </Modal.Header>
      {showCustomModal ? (
        <Modal.Body>
          <div className='d-flex justify-content-left flex-column mt-3'>
            <span className='custommodal-report mb-3'>Report</span>
            <Dropdown className='custom-modal'>
              <Dropdown.Toggle variant='none' id='dropdown-basic'>
                Custom
              </Dropdown.Toggle>
            </Dropdown>
          </div>
          <div className='d-flex justify-content-left flex-column mt-3'>
            <span className='custommodal-report'>Data</span>
            <div className='d-flex'>
              <div className='custom-modal-options mt-3'>
                <input type='checkbox' checked />
                <span>Vulnerabilities</span>
              </div>
              <div className='custom-modal-options mt-3 ms-4'>
                <input type='checkbox' checked />
                <span>Remediations</span>
              </div>
            </div>
          </div>
          <div className='d-flex justify-content-left flex-column mt-3'>
            <span className='custommodal-report'>Group Vulnerabilities by</span>
            <div>
              <Dropdown className='custom-modal mt-3'>
                <Dropdown.Toggle variant='none' id='dropdown-basic'>
                  Host
                </Dropdown.Toggle>
              </Dropdown>
            </div>
            <div className='d-flex'>
              <div className='custom-modal-options mt-3'>
                <input type='checkbox' checked />
                <span>Scan Information</span>
              </div>
              <div className='custom-modal-options mt-3 ms-4'>
                <input type='checkbox' checked />
                <span>Host Information</span>
              </div>
            </div>
          </div>
          <div className='d-flex justify-content-left flex-column mt-3'>
            <span className='custommodal-report'>Vulnerability Details</span>
            <div className='d-flex'>
              <div className='custom-modal-options mt-3'>
                <input type='checkbox' checked />
                <span>Synopsis</span>
              </div>
              <div className='custom-modal-options mt-3 ms-4'>
                <input type='checkbox' checked />
                <span>CVV v2.0 Base Score</span>
              </div>
              <div className='custom-modal-options mt-3 ms-4'>
                <input type='checkbox' checked />
                <span>Description</span>
              </div>
              <div className='custom-modal-options mt-3 ms-4'>
                <input type='checkbox' checked />
                <span>CVV v2.0 Temporal Score</span>
              </div>
            </div>
          </div>
          <div className='d-flex justify-content-left flex-column mt-3'>
            <div className='d-flex'>
              <div className='custom-modal-options mt-3'>
                <input type='checkbox' checked />
                <span>See also</span>
              </div>
              <div className='custom-modal-options mt-3 ms-4'>
                <input type='checkbox' checked />
                <span>STIG severity</span>
              </div>
              <div className='custom-modal-options mt-3 ms-4'>
                <input type='checkbox' checked />
                <span>Solution</span>
              </div>
              <div className='custom-modal-options mt-3 ms-4'>
                <input type='checkbox' checked />
                <span>References</span>
              </div>
            </div>
          </div>
          <div className='d-flex justify-content-left flex-column mt-3'>
            <div className='d-flex'>
              <div className='custom-modal-options mt-3'>
                <input type='checkbox' checked />
                <span>Risk factor</span>
              </div>
              <div className='custom-modal-options mt-3 ms-4'>
                <input type='checkbox' checked />
                <span>Exploitable with</span>
              </div>
              <div className='custom-modal-options mt-3 ms-4'>
                <input type='checkbox' checked />
                <span>CVV v2.0 Base Score</span>
              </div>
              <div className='custom-modal-options mt-3 ms-4'>
                <input type='checkbox' checked />
                <span>Plugin Information</span>
              </div>
            </div>
          </div>
          <div className='d-flex justify-content-left flex-column mt-3'>
            <div className='d-flex'>
              <div className='custom-modal-options mt-3'>
                <input type='checkbox' checked />
                <span>CVV v2.0 Temporal</span>
              </div>
              <div className='custom-modal-options mt-3 ms-4'>
                <input type='checkbox' checked />
                <span>Plugin Output</span>
              </div>
            </div>
          </div>
          <div className='my-3'>
            <span className='custommodal-note'>
              Some Vulnerability details do not exist in all results
            </span>
          </div>
          <div>
            <span>Formating Options</span>
          </div>
          <div className='custom-modal-options-format mt-3'>
            <input type='checkbox' checked />
            <span>Include page break betweek Vulnerability results</span>
          </div>
        </Modal.Body>
      ) : (
        <Modal.Body>
          <div className='d-flex justify-content-center mt-3'>
            <span className='pdfmodal-report'>Report</span>
            <Dropdown className='pdf-modal'>
              <Dropdown.Toggle variant='none' id='dropdown-basic'>
                Executive
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item href='#' style={{ width: '190px' }}>
                  <span className='mx-2'>Executive Summary</span>
                </Dropdown.Item>

                <Dropdown.Item
                  href='#'
                  onClick={() => setShowCustomModal(true)}
                  style={{ width: '190px' }}
                >
                  <span className='mx-2'>Custom</span>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </Modal.Body>
      )}
      <Modal.Footer>
        <div className='footer-buttons-modalpdf'>
          <button className='mx-3' onClick={props.onHide}>
            Cancel
          </button>
          <button className='mx-3'>Generate Report</button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

export default GeneratePdfModal;
