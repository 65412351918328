// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.page404-container {
  height: 100vh !important;
  display: flex !important;
}

.lottie-page404 {
  width: 200vh;
  height: 100vh;
  margin: auto;
}`, "",{"version":3,"sources":["webpack://./src/screens/page404/styles.scss"],"names":[],"mappings":"AAAA;EACI,wBAAA;EACA,wBAAA;AACJ;;AAEA;EACG,YAAA;EACA,aAAA;EACA,YAAA;AACH","sourcesContent":[".page404-container{\r\n    height: 100vh !important;\r\n    display: flex !important;\r\n}\r\n\r\n.lottie-page404{\r\n   width: 200vh;\r\n   height: 100vh;\r\n   margin: auto;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
