// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container-modal {
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  width: "100vw";
  height: "100vh";
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
}`, "",{"version":3,"sources":["webpack://./src/components/loader/styles.scss"],"names":[],"mappings":"AAEA;EACI,eAAA;EACA,QAAA;EACA,UAAA;EACA,WAAA;EACA,SAAA;EACA,cAAA;EACA,eAAA;EACC,aAAA;EACD,mBAAA;EACA,uBAAA;EACA,oCAAA;AADJ","sourcesContent":["\r\n\r\n.container-modal{\r\n    position:fixed;\r\n    top:0px;\r\n    right:0px;\r\n    bottom:0px;\r\n    left:0px;\r\n    width: \"100vw\";\r\n    height: \"100vh\";\r\n     display: flex;\r\n    align-items: center;\r\n    justify-content: center;\r\n    background-color: rgba(0,0,0,0.5);\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
