import React from 'react';

const CSVIcon = ({ active }) => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M16.125 15.75L18 20.25L19.875 15.75'
      stroke='#B5B5B5'
      stroke-width='1.5'
      stroke-linecap='round'
      stroke-linejoin='round'
    />
    <path
      d='M7.5 19.7344C7.14117 20.0624 6.67366 20.2461 6.1875 20.25C5.04375 20.25 4.125 19.2469 4.125 18C4.125 16.7531 5.04375 15.75 6.1875 15.75C6.67366 15.7539 7.14117 15.9376 7.5 16.2656'
      stroke='#B5B5B5'
      stroke-width='1.5'
      stroke-linecap='round'
      stroke-linejoin='round'
    />
    <path
      d='M10.5 19.875C10.9063 20.1781 11.3994 20.3424 11.9062 20.3438C12.75 20.3437 13.5 20.0625 13.5 19.125C13.5 17.625 10.5 18.2812 10.5 16.875C10.5 16.125 11.0625 15.6562 11.9062 15.6562C12.4131 15.6576 12.9062 15.8219 13.3125 16.125'
      stroke='#B5B5B5'
      stroke-width='1.5'
      stroke-linecap='round'
      stroke-linejoin='round'
    />
    <path
      d='M5 12V1.91667C5 1.67355 5.07902 1.44039 5.21967 1.26849C5.36032 1.09658 5.55109 1 5.75 1H14.75L20 7.41667V12'
      stroke='#B5B5B5'
      stroke-width='1.5'
      stroke-linecap='round'
      stroke-linejoin='round'
    />
    <path
      d='M14 2V8H20'
      stroke='#B5B5B5'
      stroke-width='1.5'
      stroke-linecap='round'
      stroke-linejoin='round'
    />
  </svg>
);

export default CSVIcon;
