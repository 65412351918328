import React from 'react';
import Table from 'react-bootstrap/Table';
import classes from './styles.scss';

import NormalCheckbox from '../NormalCheckbox';
const NormalTable = (props) => {
  const {
    headers,
    children,
    isCentred = false,
    styles = {},
    isleft = false,
    className,
    isCenter = false,
    isAlign = false,
    tasktable = false,
    righttable = false,
    multiselect,
    multiSelect,
    headerCheckbox,
    handleHeaderCheckbox,
    centered = false,
    setwidth = false,
    tableAlign = false,
    taskCenter = false,
    aligncenter = false,
  } = props;

  return (
    <div
      style={styles}
      className={`${classes.overAllTable} ${tasktable && classes.rightstyle} ${
        righttable && classes.rightcenter
      } ${isCentred && classes.iscenter} ${isleft && classes.leftstyle} ${
        isAlign && classes.alignstyle
      } ${isCenter && classes.center} ${centered && classes.alignCenter} ${
        tableAlign && classes.alignEnd
      } ${taskCenter && classes.taskCenter} ${
        aligncenter && classes.aligncenter
      }`}
    >
      <Table
        className={`${classes.normalTable} mb-1 mt-1` + className}
        responsive
        bordered
      >
        <thead>
          <tr>
            {multiselect && (
              <th>
                <NormalCheckbox
                  name='selectAll'
                  onChange={handleHeaderCheckbox}
                  checked={headerCheckbox}
                />
              </th>
            )}
            {multiSelect && (
              <th>
                <div className='d-flex'>
                  <div>
                    <NormalCheckbox
                      name='selectAll'
                      onChange={handleHeaderCheckbox}
                      checked={headerCheckbox}
                    />
                  </div>
                  <div className='mx-2'>All</div>
                </div>
              </th>
            )}
            {headers.map((header, index) => {
              return (
                <th
                  key={index}
                  className={setwidth ? classes.minwidth : undefined}
                >
                  {' '}
                  <b>{header}</b>
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>{children}</tbody>
      </Table>
    </div>
  );
};

export default NormalTable;
