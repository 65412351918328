import React from 'react';

const UserManagementIcon = ({ active }) => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fill-rule='evenodd'
      clip-rule='evenodd'
      d='M9 1.25C7.74022 1.25 6.53204 1.75044 5.64124 2.64124C4.75045 3.53204 4.25 4.74022 4.25 6C4.25 7.25978 4.75045 8.46796 5.64124 9.35876C6.53204 10.2496 7.74022 10.75 9 10.75C10.2598 10.75 11.468 10.2496 12.3588 9.35876C13.2496 8.46796 13.75 7.25978 13.75 6C13.75 4.74022 13.2496 3.53204 12.3588 2.64124C11.468 1.75044 10.2598 1.25 9 1.25ZM5.75 6C5.75 5.13805 6.09241 4.3114 6.7019 3.7019C7.3114 3.09241 8.13805 2.75 9 2.75C9.86195 2.75 10.6886 3.09241 11.2981 3.7019C11.9076 4.3114 12.25 5.13805 12.25 6C12.25 6.86195 11.9076 7.6886 11.2981 8.2981C10.6886 8.90759 9.86195 9.25 9 9.25C8.13805 9.25 7.3114 8.90759 6.7019 8.2981C6.09241 7.6886 5.75 6.86195 5.75 6Z'
      fill={active ? `#ffffff` : `#8A92A6`}
    />
    <path
      d='M15 2.25C14.8011 2.25 14.6103 2.32902 14.4697 2.46967C14.329 2.61032 14.25 2.80109 14.25 3C14.25 3.19891 14.329 3.38968 14.4697 3.53033C14.6103 3.67098 14.8011 3.75 15 3.75C15.5967 3.75 16.169 3.98705 16.591 4.40901C17.0129 4.83097 17.25 5.40326 17.25 6C17.25 6.59674 17.0129 7.16903 16.591 7.59099C16.169 8.01295 15.5967 8.25 15 8.25C14.8011 8.25 14.6103 8.32902 14.4697 8.46967C14.329 8.61032 14.25 8.80109 14.25 9C14.25 9.19891 14.329 9.38968 14.4697 9.53033C14.6103 9.67098 14.8011 9.75 15 9.75C15.9946 9.75 16.9484 9.35491 17.6517 8.65165C18.3549 7.94839 18.75 6.99456 18.75 6C18.75 5.00544 18.3549 4.05161 17.6517 3.34835C16.9484 2.64509 15.9946 2.25 15 2.25Z'
      fill={active ? `#ffffff` : `#8A92A6`}
    />
    <path
      fill-rule='evenodd'
      clip-rule='evenodd'
      d='M3.678 13.52C5.078 12.72 6.961 12.25 9 12.25C11.04 12.25 12.922 12.72 14.322 13.52C15.7 14.308 16.75 15.51 16.75 17C16.75 18.49 15.7 19.692 14.322 20.48C12.922 21.28 11.039 21.75 9 21.75C6.96 21.75 5.078 21.28 3.678 20.48C2.3 19.692 1.25 18.49 1.25 17C1.25 15.51 2.3 14.308 3.678 13.52ZM4.422 14.823C3.267 15.483 2.75 16.28 2.75 17C2.75 17.72 3.267 18.517 4.422 19.177C5.556 19.825 7.173 20.25 9 20.25C10.827 20.25 12.444 19.825 13.578 19.177C14.733 18.517 15.25 17.719 15.25 17C15.25 16.28 14.733 15.483 13.578 14.823C12.444 14.175 10.827 13.75 9 13.75C7.173 13.75 5.556 14.175 4.422 14.823Z'
      fill={active ? `#ffffff` : `#8A92A6`}
    />
    <path
      d='M18.1598 13.2668C17.9654 13.2243 17.7621 13.2609 17.5946 13.3683C17.4271 13.4758 17.3092 13.6454 17.2668 13.8398C17.2243 14.0342 17.2609 14.2375 17.3683 14.4049C17.4758 14.5724 17.6454 14.6903 17.8398 14.7328C18.6318 14.9058 19.2648 15.2048 19.6828 15.5468C20.1008 15.8888 20.2498 16.2238 20.2498 16.4998C20.2498 16.7498 20.1298 17.0448 19.7968 17.3538C19.4618 17.6648 18.9468 17.9518 18.2838 18.1518C18.1894 18.1801 18.1016 18.2268 18.0253 18.2891C17.9489 18.3514 17.8856 18.4282 17.839 18.515C17.7923 18.6017 17.7632 18.6968 17.7533 18.7949C17.7434 18.8929 17.7529 18.9919 17.7813 19.0863C17.8096 19.1806 17.8563 19.2685 17.9186 19.3448C17.9809 19.4211 18.0577 19.4844 18.1445 19.5311C18.2312 19.5777 18.3263 19.6068 18.4244 19.6167C18.5224 19.6266 18.6214 19.6171 18.7158 19.5888C19.5388 19.3408 20.2738 18.9578 20.8178 18.4528C21.3638 17.9458 21.7498 17.2788 21.7498 16.4998C21.7498 15.6348 21.2758 14.9118 20.6328 14.3858C19.9888 13.8588 19.1218 13.4778 18.1598 13.2668Z'
      fill={active ? `#ffffff` : `#8A92A6`}
    />
  </svg>
);

export default UserManagementIcon;
