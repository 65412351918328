import React, { useState } from 'react';
import './styles.scss';
import Assets from '../../assets';
import dropdown from '../../assets/icons/dropdown.png';
import { ReactComponent as CuttingEdgeLogo } from '../../assets/svg/cutting_edge_logo.svg';
import { useNavigate } from 'react-router-dom';

const Header = () => {
  const navigate = useNavigate();

  const [activeMenuItem, setActiveMenuItem] = useState('home'); // Set the active menu item

  const handleMenuItemClick = (menuItem, url) => {
    setActiveMenuItem(menuItem);
    navigate(url);
  };

  return (
    <div className='header-container1'>
      <div className='container'>
        <div className='d-flex justify-content-between'>
          <div className='navbar-left'>
            <CuttingEdgeLogo className='logo' alt='Logo' />
            <span
              className={`menu-item ${activeMenuItem === 'home' ? 'active' : ''}`}
              onClick={() => handleMenuItemClick('home', '/admin/dashboard')}
            >
              Home
            </span>
            <span
              className={`menu-item ${activeMenuItem === 'scans' ? 'active' : ''}`}
              onClick={() => handleMenuItemClick('scans', '/admin/my-scans')}
            >
              Scans
            </span>

            <span
              className={`menu-item ${activeMenuItem === 'report' ? 'active' : ''}`}
              // onClick={() => handleMenuItemClick('report', '/admin/report')}
            >
            Report
            </span>
            <span
              className={`menu-item ${activeMenuItem === 'settings' ? 'active' : ''}`}
              // onClick={() => handleMenuItemClick('settings', '/admin/settings')}
            >
            Settings
            </span>
          </div>
          <div className='navbar-right d-flex'>
            <img
              src={Assets.Images.notification}
              alt='Notification Icon'
              className='notification-icon'
            />
            <img
              src={Assets.Images.divider}
              alt='divider icon'
              className='divider-icon'
            />
            <img
              src={Assets.Images.profilePic}
              alt='Profile Picture'
              className='profile-pic'
            />
            <span>Brian</span>
            <img src={dropdown} alt='Dropdown Icon' className='dropdown-icon' />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
