import React from 'react';
import './styles.scss';

const ProgressBar = () => {
  return (
    <div
      class='progress'
      role='progressbar'
      aria-label='Basic example'
      aria-valuenow='98'
      aria-valuemin='0'
      aria-valuemax='100'
    >
      <div class='progress-bar w-75'></div>
    </div>
  );
};

export default ProgressBar;
