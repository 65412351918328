import React, { useState } from 'react';
import './styles.scss';
import Assets from '../../assets';
import { useNavigate } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import Utils from '../../utils';
import SVG from '../../assets/svg';

const Scans = () => {
  const navigate = useNavigate();

  const UiNavigate = () => {
    // navigate('/admin/new-scan');
  };

  const UiNavigateToScanDetails = () => {
    navigate('/admin/new-scan/scan-templates/host-discovery/overview');
  };

  const [tableData, setTableData] = useState([
    {
      name: 'Host Discovery - Local Network',
      schedule: 'On Demand',
      lastModified: '17 Aug, 2023',
      status: 'In progress',
      action: ['pause', 'stop'],
    },
    {
      name: 'Host Discovery - Local Network',
      schedule: 'On Demand',
      lastModified: '17 Aug, 2023',
      status: 'Failed',
      action: ['refresh', 'delete', 'edit'],
    },
    {
      name: 'Host Discovery - Local Network',
      schedule: 'On Demand',
      lastModified: '17 Aug, 2023',
      status: 'Scheduled',
      action: ['edit'],
    },
    {
      name: 'Host Discovery - Local Network',
      schedule: 'On Demand',
      lastModified: '17 Aug, 2023',
      status: 'In progress',
      action: ['delete'],
    },
    {
      name: 'Host Discovery - Local Network',
      schedule: 'On Demand',
      lastModified: '17 Aug, 2023',
      status: 'Completed',
      action: ['pause'],
    },
    {
      name: 'Host Discovery - Local Network',
      schedule: 'On Demand',
      lastModified: '17 Aug, 2023',
      status: 'In progress',
      action: ['edit'],
    },
  ]);
  return (
    <div className='sub-header-container'>
      <div className='container'>
        <div className='sub-header-content'>
          <span className='myscans-text'>MY SCANS</span>
          <div className='options-btn'>
            <button>Import</button>
            <button>New Folder</button>
            <button onClick={UiNavigate}>New Scan</button>
          </div>
        </div>

        <div className='table-align hdhistory-content'>
          <div className='hdhistory-subcontent'>
            <span className='hosts-title'>Scan List</span>
            <p className='hosts-subtext'>
              Recent Expeditions: Your Latest Vulnerability Searches
            </p>
            <div className='underline-hdhistory'></div>
            <div className='search-filter-hdhistory'>
              <div className='search-hdhistory'>
                <img src={Assets.Icons.search} alt='Search Icon' />
                <input type='text' placeholder='Search for hosts' />
              </div>
              <div className='approverStatus-dropdown'>
                <Dropdown>
                  <Dropdown.Toggle variant='none' id='dropdown-basic'>
                    Approver Status
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item href='#'>
                      <input type='checkbox' height={'22px'} width={'22px'} />
                      <span className='mx-2'>Define Rule</span>
                    </Dropdown.Item>
                    <Dropdown.Item href='#'>
                      <input type='checkbox' height={'22px'} width={'22px'} />
                      <span className='mx-2'>Configure Rule</span>
                    </Dropdown.Item>
                    <Dropdown.Item href='#'>
                      <input type='checkbox' height={'22px'} width={'22px'} />
                      <span className='mx-2'>Set Values</span>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
          <div>
            <table className='custom-table-hdhistory'>
              <thead>
                <tr>
                  <th className='table-header'>
                    {' '}
                    <input type='checkbox' className='checkbox-hdhistory' />
                    Name
                  </th>
                  <th className='table-header'>
                    Schedule
                    <img src={Assets.Icons.upAndDownArrow} className='ms-2' />
                  </th>
                  <th className='table-header'>
                    Last Modified
                    <img src={Assets.Icons.upAndDownArrow} className='ms-2' />
                  </th>
                  <th className='table-header'>Status</th>
                  <th className='table-header'>Action</th>
                </tr>
              </thead>
              <tbody>
                {tableData.map((item, index) => (
                  <tr
                    key={index}
                    className={index % 2 === 1 ? 'colored-row' : 'white-row'}
                  >
                    <td
                      className='table-data'
                      style={{ width: '30%' }}
                      onClick={UiNavigateToScanDetails}
                    >
                      <input type='checkbox' className='checkbox-hdhistory' />
                      {item.name}
                    </td>
                    <td className='table-data'>{item.schedule} </td>
                    <td className='table-data'>{item.lastModified} </td>
                    <td className='table-data'>
                      <div
                        className='badge-hdhistory'
                        style={{
                          ...Utils.dynamicBadgeStyle.getStatusBadgeStyle(
                            item.status
                          ),
                        }}
                      >
                        {item.status}
                      </div>{' '}
                    </td>
                    <td className='table-data'>
                      {item.action.length > 0 && (
                        <div className='action-icon-box'>
                          {item.action.map((action, index) => (
                            <React.Fragment key={index}>
                              {action === 'pause' && (
                                <SVG.Pause className='action-icon' />
                              )}
                              {action === 'stop' && (
                                <SVG.Stop className='action-icon' />
                              )}
                              {action === 'edit' && (
                                <SVG.Edit className='action-icon' />
                              )}
                              {action === 'refresh' && (
                                <SVG.Refresh className='action-icon' />
                              )}
                              {action === 'delete' && (
                                <SVG.Delete className='action-icon' />
                              )}
                            </React.Fragment>
                          ))}
                        </div>
                      )}
                    </td>{' '}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>{' '}
          <br />
        </div>
      </div>
    </div>
  );
};

export default Scans;
