import React, { useEffect, useState } from 'react';
import './styles.scss';
import malwareIcon from '../../assets/images/malwareIcon.png';
import { useNavigate } from 'react-router-dom';
import Card from '../../components/Card';
import NormalTable from '../../components/NormalTable';
import NoData from '../../components/NoData';
import Assets from '../../assets';
import SVG from '../../assets/svg';
import Utils from '../../utils';

const NewScan = () => {
  const tableHeader = [
    'Name',
    'Schedule',
    'Last Modified',
    'Status',
    'Actions',
  ];
  const [tableList, setTableList] = useState([
    {
      name: 'Host Discovery - Local Network',
      schedule: 'On Demand',
      lastModified: '17 Aug, 2023',
      status: 'In progress',
      action: ['pause', 'stop'],
    },
    {
      name: 'Host Discovery - Local Network',
      schedule: 'On Demand',
      lastModified: '17 Aug, 2023',
      status: 'Completed',
      action: ['pause'],
    },
    {
      name: 'Host Discovery - Local Network',
      schedule: 'On Demand',
      lastModified: '17 Aug, 2023',
      status: 'Scheduled',
      action: ['edit'],
    },
    {
      name: 'Host Discovery - Local Network',
      schedule: 'On Demand',
      lastModified: '17 Aug, 2023',
      status: 'Failed',
      action: ['refresh', 'delete', 'edit'],
    },
    {
      name: 'Host Discovery - Local Network',
      schedule: 'On Demand',
      lastModified: '17 Aug, 2023',
      status: 'In progress',
      action: ['delete'],
    },
    {
      name: 'Host Discovery - Local Network',
      schedule: 'On Demand',
      lastModified: '17 Aug, 2023',
      status: 'In progress',
      action: ['edit'],
    },
  ]);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [limit, setLimit] = useState(10);
  const [pageMeta, setPageMeta] = useState({
    pageCount: 0,
  });

  const handlePageChange = (res) => {
    setPage(res);
  };

  const navigate = useNavigate();

  const UiNavigate = () => {
    navigate('/admin/scans');
  };
  const UiNavigateToScanDetails = () => {
    navigate('/admin/new-scan/scan-templates/host-discovery/overview');
  };

  const FolderCard = ({ iconUrl, name, number }) => (
    <div className='card'>
      <div className='card-content'>
        <div className='icon'>
          <img src={iconUrl} alt='Folder' />
        </div>
        <div className='info'>
          <div className='name-number'>
            <span>
              <div className='name'>{name}</div>
            </span>
            <span>
              <div className='number'>{number}</div>
            </span>
          </div>
        </div>
      </div>
    </div>
  );

  const [cardsData, setCardsData] = useState([
    { iconUrl: Assets.Icons.folder, name: 'Folder 1', number: 12 },
    { iconUrl: Assets.Icons.folder, name: 'Folder 2', number: 12 },
    { iconUrl: Assets.Icons.folder, name: 'Folder 3', number: 12 },
    { iconUrl: Assets.Icons.folder, name: 'Folder 4', number: 12 },
    { iconUrl: Assets.Icons.folder, name: 'Folder 5', number: 12 },
    { iconUrl: Assets.Icons.folder, name: 'Folder 5', number: 12 },
  ]);

  return (
    <div className='sub-header-container'>
      <div className='container'>
        <div className='sub-header-content1'>
          <div>
            <span className='dashboard-text'>DASHBOARD</span>
          </div>
        </div>
        <br />
        <br />
        <div className='content-area'>
          <div className='row my-4'>
            <div className='col-lg-2 col-sm-12 my-2'>
              <Card cardHeading='Scans in Queue'>
                <p className='stat-num-text'>{4}</p>
              </Card>
            </div>
            <div className='col-lg-2 col-sm-12 my-2'>
              <Card cardHeading='Scans in Queue'>
                <p>
                  <span className='stat-num-text'>{2}</span>
                </p>
              </Card>
            </div>
            <div className='col-lg-2 col-sm-12 my-2'>
              <Card cardHeading='Scans in Queue'>
                <p>
                  <span className='stat-num-text'>{4}</span>
                </p>
              </Card>
            </div>

            <div className='col-lg-4 col-sm-12 my-2'>
              <Card cardHeading='Start New Scan'>
                <div className='start-new-scan'>
                  <p className='flex-container'>
                    <span>Begin Your Vulnerability Search</span>
                    <button
                      className='create-new-scan-btn'
                      onClick={UiNavigate}
                    >
                      Create
                    </button>
                  </p>
                </div>
              </Card>
            </div>
          </div>

          <div className='subtitle'>
            <span className='subtitle-text'>Folders</span>

            <div className='card-row'>
              {cardsData.map((card, index) => (
                <FolderCard
                  key={index}
                  iconUrl={card.iconUrl}
                  name={card.name}
                  number={card.number}
                />
              ))}
              <div className='card'>
                <div className='card-content'>
                  <div className='icon'>
                    <img src={Assets.Icons.folder} alt='Add folder' />
                  </div>
                  <div className='info'>
                    <div className='name-number'>
                      <span>
                        <div className='name'>Add Folder</div>
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className='card'
                style={{
                  border: '2px dashed #0094FF',
                  textAlign: 'center',
                }}
              >
                <div className='card-content'>
                  <div
                    className='icon'
                    style={{
                      background: 'transparent',
                      textAlign: 'center',
                      'padding-left': '26px',
                    }}
                  >
                    <div
                      style={{
                        fontSize: '24px',
                        color: '#0094FF',
                      }}
                    >
                      +
                    </div>
                  </div>
                  <div className='info'>
                    <div className='name-number'>
                      <span>
                        <div className='name' style={{ color: '#0094FF' }}>
                          Add New Folder
                        </div>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br />

          <div className='hdhistory-content'>
            <div className='hdhistory-subcontent'>
              <span className='hosts-title'>Recent Scan</span>
              <p className='hosts-subtext'>
                Recent Expeditions: Your Latest Vulnerability Searches
              </p>
              {/* <div className='underline-hdhistory'></div> */}
            </div>
            <div>
              <table className='custom-table-hdhistory'>
                <thead>
                  <tr>
                    <th className='table-header'>
                      {' '}
                      <input type='checkbox' className='checkbox-hdhistory' />
                      Name
                    </th>
                    <th className='table-header'>
                      Schedule
                      <img
                        src={Assets.Icons.upAndDownArrow}
                        className='ms-2'
                        alt='Up Down Icon'
                      />
                    </th>
                    <th className='table-header'>
                      Last Modified
                      <img
                        src={Assets.Icons.upAndDownArrow}
                        className='ms-2'
                        alt='Up Down Icon'
                      />
                    </th>
                    <th className='table-header'>Status</th>
                    <th className='table-header'>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {tableList.map((item, index) => (
                    <tr
                      key={index}
                      className={index % 2 === 1 ? 'colored-row' : 'white-row'}
                    >
                      <td
                        className='table-data'
                        style={{ width: '30%' }}
                        onClick={UiNavigateToScanDetails}
                      >
                        <input type='checkbox' className='checkbox-hdhistory' />
                        {item.name}
                      </td>
                      <td className='table-data'>{item.schedule} </td>
                      <td className='table-data'>{item.lastModified} </td>
                      <td className='table-data'>
                        <div
                          className='badge-hdhistory'
                          style={{
                            ...Utils.dynamicBadgeStyle.getStatusBadgeStyle(
                              item.status
                            ),
                          }}
                        >
                          {item.status}
                        </div>
                      </td>

                      <td className='table-data'>
                        {item.action.length > 0 && (
                          <div className='action-icon-box'>
                            {item.action.map((action, index) => (
                              <React.Fragment key={index}>
                                {action === 'pause' && (
                                  <SVG.Pause className='action-icon' />
                                )}
                                {action === 'stop' && (
                                  <SVG.Stop className='action-icon' />
                                )}
                                {action === 'edit' && (
                                  <SVG.Edit className='action-icon' />
                                )}
                                {action === 'refresh' && (
                                  <SVG.Refresh className='action-icon' />
                                )}
                                {action === 'delete' && (
                                  <SVG.Delete className='action-icon' />
                                )}
                              </React.Fragment>
                            ))}
                          </div>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <br />
        </div>
      </div>
    </div>
  );
};

export default NewScan;
