import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useForm } from 'react-hook-form';
import InputField from '../../components/inputField';
import './styles.scss';
import { forgotPassword, userData } from '../../redux/reducers/userSlice';
import { useDispatch, useSelector } from 'react-redux';
import { Toast } from '../../components/toast';

function ForgotPassword(props) {
  const { register, handleSubmit, errors } = useForm({ mode: 'onChange' });

  const dispatch = useDispatch();

  let user = useSelector(userData);

  const onSubmit = (data) => {
    console.log(data, 'forgot');
    if (!data.email) {
      Toast({
        type: 'error',
        message: 'Please enter your email address',
      });
      return;
    }
    let formData = {
      email: data.email,
    };
    dispatch(forgotPassword(formData));
    props.onHide();
  };
  return (
    <Modal
      {...props}
      size='md'
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Header>
        <Modal.Title
          id='contained-modal-title-vcenter'
          className='forgot_password_title'
        >
          Forgot Password
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit(onSubmit)} style={{ marginLeft: '55px' }}>
          <label className='email_username font-regular-13  mt-3'>
            Enter your registered email address
          </label>
          <br />

          <div className='email'>
            <InputField
              className='user_input_forgotPassword'
              type='text'
              placeholder='Email ID'
              name='email'
              value=''
              register={register({
                required: true,
                pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              })}
              isEmail
              error={errors.email}
              messages={{
                required: '*Email is required',
                pattern: 'Email Id is invaild,Please check email address',
              }}
            />
          </div>
          <br />
          <button className='sendOtp_button mb-4' type='submit'>
            Send OTP
          </button>
        </form>
      </Modal.Body>
    </Modal>
  );
}

export default ForgotPassword;
