const routers = [
  {
    layout: 'AuthLayout',
    path: '/auth',
    childrens: [
      {
        component: 'Login',
        childPath: '/login',
      },
    ],
  },
  {
    layout: 'MainLayout',
    path: '/admin',
    childrens: [
      {
        component: 'Dashboard',
        childPath: '/dashboard',
      },
      {
        component: 'Scans',
        childPath: '/scans',
      },
      {
        component: 'NewScan',
        childPath: '/new-scan',
      },
      {
        component: 'MyScans',
        childPath: '/my-scans',
      },
      {
        component: 'AdvancedScan',
        childPath: '/advanced-scan',
      },
      {
        component: 'HostDiscoveryOverview',
        childPath: '/new-scan/scan-templates/host-discovery/overview',
      },
      {
        component: 'HostDiscoveryHosts',
        childPath: '/new-scan/scan-templates/host-discovery/hosts',
      },
      {
        component: 'HostDiscoveryVulnerabilities',
        childPath: '/new-scan/scan-templates/host-discovery/vulnerabilities',
      },
      {
        component: 'HostDiscoveryRemediations',
        childPath: '/new-scan/scan-templates/host-discovery/remediations',
      },
      {
        component: 'HostDiscoveryVprTopThreats',
        childPath: '/new-scan/scan-templates/host-discovery/vpr-top-threats',
      },
      {
        component: 'HostDiscoveryHistory',
        childPath: '/new-scan/scan-templates/host-discovery/history',
      },
      {
        component: 'ChangePassword',
        childPath: '/change-password',
      },
      {
        component: 'UserManagement',
        childPath: '/user-management',
      },
      {
        component: 'AddUsers',
        childPath: '/add-user',
      },
    ],
  },
  {
    init: '/auth/login',
    path: '/',
  },
  {
    component: 'Page404',
    path: '*',
  },
];

export default routers;
