import React, { useEffect, useState } from 'react';
import './styles.scss';
import InputField from '../../components/inputField';
import PrimaryButton from '../../components/primaryButton';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import SVG from '../../assets/svg';
import Assets from '../../assets';

import {
  loginUser,
  saveUserData,
  userData,
} from '../../redux/reducers/userSlice';
import { colors } from '@mui/material';
import ForgotPassword from '../forgotPassword';

const LoginScreen = (props) => {
  const [navLink] = useState([
    {
      to: '/admin/dashboard',
      label: 'Dashboard',
      Icon: SVG.DashboardIcon,
      id: '1',
    },
    {
      to: '/admin/user-management',
      label: 'User Management',
      Icon: SVG.UserManagementIcon,
      id: '4',
    },
    {
      to: '/admin/change-password',
      label: 'Change Password',
      Icon: SVG.ChangePasswordIcon,
      id: '5',
    },
  ]);

  const { register, handleSubmit, errors } = useForm({ mode: 'onChange' });

  const [showPassword, setShowPassword] = useState(false);
  const [showForgotPassword, setShowForgotPassword] = useState(false);

  const openForgotPassword = () => {
    setShowForgotPassword(true);
  };

  const closeForgotPassword = () => {
    setShowForgotPassword(false);
  };

  const dispatch = useDispatch();

  let data = useSelector(userData);

  const navigate = useNavigate();

  const navigateUI = () => {
    navigate('/admin/dashboard');
    return () => {};
  };
  const onSubmit = (data) => {
    if (!showForgotPassword) {
      navigateUI();
      // loginUser(data).then(async res => {
      //   dispatch(saveUserData(res));
      //   let item = {...res};
      //   let permission = [...item?.userType?.permission];
      //   await localStorage.setItem('TOKEN',item?.token);
      //   let navLinks = [];
      //   for (let i = 0; i < permission.length; i++) {
      //     const element = permission[i];
      //     let backup = [...navLink];
      //     let items = backup.filter(i => i.id === `${element}`);
      //     navLinks = [...navLinks, ...items];
      //   };
      //   console.log(navLinks,'navLinks');
      //   let path = navLinks[0].to;
      //   navigate(`${path}`)
      // })
    }
  };

  return (
    <div className='login-body'>
      <div className='login_page'>
        <div className='ms-5 mt-5'>
          <div className='d-flex align-items-center'>
            <h3 className='text-center font-regular-29 white_text logo_title'>
              AUTOPILOT
            </h3>
            <div className='separator'></div>
            <img
              src={Assets.Images.cutting_edge_logo}
              className='cutting_edge_logo'
              alt='cutting_edge_logo'
            />
          </div>

          <form onSubmit={handleSubmit(onSubmit)}>
            <label className='email_username font-regular-13 white_text mt-3 '>
              Email ID
            </label>
            <br />

            <div className='email'>
              <InputField
                className='user_input'
                type='text'
                placeholder='Email ID'
                name='email'
                value=''
                register={register({
                  required: true,
                  pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                })}
                error={errors.email}
                isEmail
                messages={{
                  required: '*Email is required',
                  pattern: 'Email Id is invaild,Please check email address',
                }}
              />
            </div>
            <br />
            <label className='email_username font-regular-13 white_text'>
              Password
            </label>
            {/* <br /> */}

            <div className='password_top'>
              <InputField
                name='password'
                register={register({
                  required: true,
                  pattern:
                    /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,15}$/,
                })}
                error={errors.password}
                isPassword
                viewPassword={showPassword}
                togglePassword={() => setShowPassword(!showPassword)}
                messages={{
                  required: 'Password is required',
                  pattern: 'Please enter valid password',
                }}
                placeholder='Password'
              />
            </div>
            <label
              className='logforgot_password'
              onClick={() => openForgotPassword()}
            >
              Forgot password
            </label>

            <ForgotPassword
              show={showForgotPassword}
              onHide={() => closeForgotPassword()}
            />

            <br />
            <button className='signin_button' type='submit'>
              Sign in
            </button>
          </form>
        </div>
      </div>{' '}
    </div>
  );
};

export default LoginScreen;
