import React from 'react';
import './style.scss';

const Card = ({
  children,
  cardHeading,
  billableValue,
  nonBillableValue,
  sperator,
  burnedHoursValue,
  totalHoursValue,
  budgetValue,
  isProject = false,
}) => {
  return (
    <div className='card-box px-3 py-2'>
      <p className={isProject ? 'ongoinghead' : 'card-heading font-regular-12'}>
        {cardHeading}
      </p>
      {children}
    </div>
  );
};

export default Card;
