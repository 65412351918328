import React from 'react';
import './styles.scss';
import Assets from '../../assets';
import { useNavigate } from 'react-router-dom';

const Scans = () => {
  const navigate = useNavigate();

  const UiNavigate = () => {
    // navigate('/admin/new-scan');
  };

  return (
    <div className='sub-header-container'>
      <div className='container'>
        <div className='sub-header-content'>
          <span className='myscans-text'>MY SCANS</span>
          <div className='options-btn'>
            <button>Import</button>
            <button>New Folder</button>
            <button onClick={UiNavigate}>New Scan</button>
          </div>
        </div>
        <div className='main-content'>
          <img src={Assets.Images.scannerImg} alt='Scanner Icon' />
          <span className='start-scan-text'>Start by Scanning</span>
          <p>Commence Exploration: Initiate Your Scanning Journey</p>
          <button onClick={UiNavigate}>New Scan</button>
        </div>
      </div>
    </div>
  );
};

export default Scans;
