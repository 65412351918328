import React from 'react';
import './styles.scss';
import { useNavigate } from 'react-router-dom';
import Assets from '../../assets';

const NewScan = () => {
  const navigate = useNavigate();

  const UiNavigate = () => {
    navigate('/admin/scans');
  };
  const UiNavigateAdv = () => {
    navigate('/admin/advanced-scan');
  };

  return (
    <div className='sub-header-container'>
      <div className='container'>
        <div className='sub-header-content'>
          <div>
            <img
              src={Assets.Icons.backArrow}
              alt='back arrow icon'
              className='back-arrow'
              onClick={UiNavigate}
            />
            <span className='newscan-text'>NEW SCAN</span>
            <div>
              <span className='scan-type'>
                <span>Scan</span>{' '}
                <img src={Assets.Icons.greaterIcon} alt='Greater Icon' />{' '}
                <span>New Scan</span>
              </span>
            </div>
          </div>
          <div>
            <button className='import-btn'>Import</button>
          </div>
        </div>
        <div className='content-area'>
          <div className='search-library'>
            <input type='text' placeholder='Search library' />
            <img src={Assets.Icons.search} alt='Search Icon' />
          </div>
          <div className='subtitle-newscan'>
            <span className='subtitle-text-newscan'>Discovery</span>
            <div className='image-container'>
              <div className='icon-text'>
                <img src={Assets.Images.malwareIcon} alt='Malware Icon' />
                <span className='mt-4'>Basic Scan</span>
              </div>
              <div
                className='icon-text advanced-scan-img'
                onClick={UiNavigateAdv}
              >
                <img src={Assets.Images.malwareIcon} alt='Malware Icon' />
                <span className='mt-4'>Advanced Scan</span>
              </div>
              <div className='icon-text'>
                <img src={Assets.Images.malwareIcon} alt='Malware Icon' />
                <span className='mt-4'>Web Application</span>
              </div>
              <div className='icon-text'>
                <img src={Assets.Images.malwareIcon} alt='Malware Icon' />
                <span className='mt-4'>Malware</span>
              </div>
              <div className='icon-text'>
                <img src={Assets.Images.malwareIcon} alt='Malware Icon' />
                <span className='mt-4'>Lorem Ipsum</span>
              </div>
              <div className='icon-text'>
                <img src={Assets.Images.malwareIcon} alt='Malware Icon' />
                <span className='mt-4'>Lorem Ipsum</span>
              </div>
              <div className='icon-text'>
                <img src={Assets.Images.malwareIcon} alt='Malware Icon' />
                <span className='mt-4'>Lorem Ipsum</span>
              </div>
              <div className='icon-text'>
                <img src={Assets.Images.malwareIcon} alt='Malware Icon' />
                <span className='mt-4'>Lorem Ipsum</span>
              </div>
            </div>
          </div>
          <div className='subtitle-newscan'>
            <span className='subtitle-text-newscan'>Vulnerabilities</span>
            <div className='image-container'>
              <div className='icon-text'>
                <img src={Assets.Images.malwareIcon} alt='Malware Icon' />
                <span className='mt-4 custom-text'>Lorem Ipsum</span>
              </div>
              <div className='icon-text'>
                <img src={Assets.Images.malwareIcon} alt='Malware Icon' />
                <span className='mt-4 custom-text'>Lorem Ipsum</span>
              </div>
              <div className='icon-text'>
                <img src={Assets.Images.malwareIcon} alt='Malware Icon' />
                <span className='mt-4 custom-text'>Lorem Ipsum</span>
              </div>
              <div className='icon-text'>
                <img src={Assets.Images.malwareIcon} alt='Malware Icon' />
                <span className='mt-4 custom-text'>Lorem Ipsum</span>
              </div>
              <div className='icon-text'>
                <img src={Assets.Images.malwareIcon} alt='Malware Icon' />
                <span className='mt-4 custom-text'>Lorem Ipsum</span>
              </div>
              <div className='icon-text'>
                <img src={Assets.Images.malwareIcon} alt='Malware Icon' />
                <span className='mt-4 custom-text'>Lorem Ipsum</span>
              </div>
              <div className='icon-text'>
                <img src={Assets.Images.malwareIcon} alt='Malware Icon' />
                <span className='mt-4 custom-text'>Lorem Ipsum</span>
              </div>
              <div className='icon-text'>
                <img src={Assets.Images.malwareIcon} alt='Malware Icon' />
                <span className='mt-4 custom-text'>Lorem Ipsum</span>
              </div>
              <div className='icon-text'>
                <img src={Assets.Images.malwareIcon} alt='Malware Icon' />
                <span className='mt-4 custom-text'>Lorem Ipsum</span>
              </div>
              <div className='icon-text'>
                <img src={Assets.Images.malwareIcon} alt='Malware Icon' />
                <span className='mt-4 custom-text'>Lorem Ipsum</span>
              </div>
            </div>
          </div>
          <div className='image-container'>
            <div className='icon-text custom-margin'>
              <img src={Assets.Images.malwareIcon} alt='Malware Icon' />
              <span className='mt-4'>Lorem Ipsum</span>
            </div>
            <div className='icon-text custom-margin'>
              <img src={Assets.Images.malwareIcon} alt='Malware Icon' />
              <span className='mt-4'>Lorem Ipsum</span>
            </div>
            <div className='icon-text custom-margin'>
              <img src={Assets.Images.malwareIcon} alt='Malware Icon' />
              <span className='mt-4'>Lorem Ipsum</span>
            </div>
          </div>
          <div className='subtitle-newscan compliance'>
            <span className='subtitle-text-newscan custom-margin'>
              Compliance
            </span>
            <div className='image-container'>
              <div className='icon-text'>
                <img src={Assets.Images.malwareIcon} alt='Malware Icon' />
                <span className='mt-4'>Lorem Ipsum</span>
              </div>
              <div className='icon-text'>
                <img src={Assets.Images.malwareIcon} alt='Malware Icon' />
                <span className='mt-4'>Lorem Ipsum</span>
              </div>
              <div className='icon-text '>
                <img src={Assets.Images.malwareIcon} alt='Malware Icon' />
                <span className='mt-4'>Lorem Ipsum</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewScan;
