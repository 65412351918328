export const getStatusBadgeStyle = (status) => {
  switch (status.toUpperCase()) {
    case 'IN PROGRESS':
      return {
        backgroundColor: '#EDF5FE',
        color: '#0050C0',
        border: '1px solid #0050C0',
        textAlign: 'center',
      };
    case 'COMPLETED':
      return {
        backgroundColor: '#E9FDF1',
        color: '#05603A',
        border: '1px solid #05603A',
        textAlign: 'center',
      };
    case 'SCHEDULED':
      return {
        backgroundColor: '#F5F6F7',
        color: '#2E3751',
        border: '1px solid #2E3751',
        textAlign: 'center',
      };
    case 'FAILED':
      return {
        backgroundColor: '#FEF3F2',
        color: '#B42318',
        border: '1px solid #B42318',
        textAlign: 'center',
      };
    case 'INFO':
      return {
        backgroundColor: '#E9EFFF',
        color: '#5B74B5',
        border: '1px solid #5B74B5',
        textAlign: 'center',
      };
    case 'LOW':
      return {
        backgroundColor: '#F1FFF7',
        color: '#54AC5E',
        border: '1px solid #54AC5E',
        textAlign: 'center',
      };
    case 'MIXED':
      return {
        backgroundColor: '#F5EDFB',
        color: '#9470AF',
        border: '1px solid #9470AF',
        textAlign: 'center',
      };
    case 'MEDIUM':
      return {
        backgroundColor: '#FFF8EA',
        color: '#E2AA3C',
        border: '1px solid #E2AA3C',
        textAlign: 'center',
      };
    case 'CRITICAL':
      return {
        backgroundColor: '#FEF3F2',
        color: '#D65D4D',
        border: '1px solid #D65D4D',
        textAlign: 'center',
      };
    case 'HIGH':
      return {
        backgroundColor: '#FCF3EA',
        color: '#D9842C',
        border: '1px solid #D9842C',
        textAlign: 'center',
      };
    default:
      return {
        textAlign: 'center',
      };
  }
};
