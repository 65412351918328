const Lottie = {
  page404: require('./lottie/404.json'),
  no_data: require('./lottie/no_data.json'),
};

const Images = {
  logo: require('./icons/logout.png'),
  menu_back: require('./images/menu_back.png'),
  // consulatations: require('./icons/consulatations.png'),
  customer_registration: require('./icons/customer_registration.png'),
  dashboard: require('./icons/dashboard.png'),
  logout: require('./icons/logout.png'),
  parameters: require('./icons/parameters.png'),
  staff_roles: require('./icons/staff_roles.png'),
  wallet: require('./icons/wallet.png'),
  banner: require('./images/banner.png'),
  download: require('./images/download.png'),
  cutting_edge_logo: require('./images/cutting_edge_logo.png'),
  cutting_edge_logo_main: require('./images/logo.png'),
  notification: require('./images/notifications.png'),
  divider: require('./images/divider.png'),
  profilePic: require('./images/profilePic.png'),
  folder: require('./images/folder.png'),
  scannerImg: require('./images/beatsnoop.png'),
  malwareIcon: require('./images/malwareIcon.png'),
  loginBackground: require('./images/login_background.png'),
  securityLock: require('./images/security-lock-image.png'),
  ExcelIcon: require('./images/excel.png'),
  pdfIcon: require('./images/pdfIcon.png'),
};

const Icons = {
  // consulatations: require('./icons/consulatations.png'),
  customer_registration: require('./icons/customer_registration.png'),
  dashboard: require('./icons/dashboard.png'),
  logout: require('./icons/logout.png'),
  parameters: require('./icons/parameters.png'),
  staff_roles: require('./icons/staff_roles.png'),
  wallet: require('./icons/wallet.png'),
  settings: require('./icons/settings.png'),
  hide: require('./icons/password_hide.png'),
  view: require('./icons/view.png'),
  download: require('./svg/csv_icon.svg'),
  email: require('./icons/email_icon.png'),
  folder: require('./icons/folder.png'),
  backArrow: require('./icons/arrow_back.png'),
  greaterIcon: require('./icons/greaterIcon.png'),
  search: require('./icons/search.png'),
  dropdown: require('./icons/dropdown.png'),
  dropdown2: require('./icons/dropdown-white.png'),
  filterIcon: require('./icons/Filters lines.png'),
  rightArrow: require('./icons/rightArrow.png'),
  upAndDownArrow: require('./icons/upAndDownArrow.png'),
  favicon: require('./icons/favicon.png'),
  upArrow: require('./icons/upArrow.png'),
  deleteIcon: require('./icons/deleteIcon.png'),
  uploadCloud: require('./icons/uploadCloud.png'),
  stepIcon: require('./icons/stepIcon.png'),
  nextIcon: require('./icons/Nexticon.png'),
};

const Assets = {
  Images,
  Icons,
  Lottie,
};

export default Assets;
